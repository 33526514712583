import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  Spinner,
  Text,
} from "theme-ui";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { useDebounce } from "hooks/useDebounce";
import { isValidHttpUrl } from "utils/url.utils";
import axios from "axios";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import {
  borrowAmount,
  borrowCurrency,
  borrowLoading,
  borrowRecipient,
  borrowResolvedRecipient,
  borrowTxHash,
} from "../state";
import { deployments } from "@poofcash/poof-v2-kit";
import { fromWei, isAddress, toBN, toWei } from "web3-utils";
import { useHiddenBalances } from "hooks/useHiddenBalances";
import { useBorrow } from "hooks/actions/useBorrow";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { isNom } from "utils/nom";
import { useNom } from "hooks/useNom";
import { ethers } from "ethers";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { StyledSelect } from "components/StyledSelect";
import { WalletGlobal } from "hooks/useWallet";
import Modal from "../../../components/Modal";
import { Cog, X as Close } from "../../../assets/icons";
import { Grid } from "@theme-ui/components";
import { useRequestParams } from "../../../hooks/useRequestParams";

interface IProps {
  onBorrowClick: () => void;
}

export const PickBorrow: React.FC<IProps> = ({ onBorrowClick }) => {
  const { network } = WalletGlobal.useContainer();
  const [amount, setAmount] = useRecoilState(borrowAmount);
  const [currency, setCurrency] = useRecoilState(borrowCurrency);

  React.useEffect(() => {
    setCurrency(
      deployments[network.chainId].find((d) => d.pSymbol === "pUSD")?.pSymbol ||
        deployments[network.chainId][0].pSymbol
    );
  }, [network.chainId, setCurrency]);
  const [recipient, setRecipient] = useRecoilState(borrowRecipient);
  const [settingsOpened, setSettingsOpened] = useState(false);
  const [resolvedRecipient, setResolvedRecipient] = useRecoilState(
    borrowResolvedRecipient
  );
  const [, setTxHash] = useRecoilState(borrowTxHash);
  const [loading] = useRecoilState(borrowLoading);
  const { actWithPoofAccount } = PoofAccountGlobal.useContainer();
  const [hiddenBalances] = useHiddenBalances(currency);
  const borrow = useBorrow();

  const {
    relayer,
    setSelectedRelayer,
    relayerOptions,
    usingCustomRelayer,
    setUsingCustomRelayer,
    customRelayer,
    setCustomRelayer,
    relayerFee,
  } = useRelayer(
    hiddenBalances?.[0] ? fromWei(hiddenBalances[0].amount.toString()) : null,
    currency
  );
  const breakpoint = useBreakpoint();
  const [customRelayerError, setCustomRelayerError] = React.useState<
    string | null
  >(null);

  const nom = useNom();
  React.useEffect(() => {
    if (isNom(recipient)) {
      nom.methods
        .resolve(
          ethers.utils.formatBytes32String(recipient.replace(".nom", ""))
        )
        .call()
        .then((r) => {
          setResolvedRecipient(r);
        })
        .catch(console.error);
    } else if (isAddress(recipient)) {
      setResolvedRecipient(recipient);
    } else {
      setResolvedRecipient(null);
    }
  }, [recipient, nom, setResolvedRecipient]);

  const [customRelayerUrl, setCustomRelayerUrl] = React.useState("");
  const onCustomRelayerUrlChange = useDebounce((relayerUrl) => {
    if (isValidHttpUrl(relayerUrl)) {
      axios
        .get(relayerUrl + "/status")
        .then(({ data }) =>
          setCustomRelayer({
            url: relayerUrl,
            relayerFee: data.poofServiceFee,
            miningServiceFee: data.miningServiceFee,
            gasPrices: data.gasPrices,
            celoPrices: data.celoPrices,
          })
        )
        .catch((err) =>
          setCustomRelayerError(
            `${err.message}. Make sure you remove the "/" at the end of your url`
          )
        );
      setCustomRelayerError(null);
    } else if (relayerUrl !== "") {
      setCustomRelayerError("Invalid custom relayer url format");
    }
  }, 200);

  const finalBorrowAmount = Number(amount) + Number(relayerFee);
  let maxBorrow = "0";
  if (hiddenBalances?.[0] && relayerFee) {
    maxBorrow = fromWei(
      hiddenBalances[0].amount
        .sub(toBN(hiddenBalances[0].debt.toString()))
        .sub(toBN(toWei(relayerFee)))
        .toString()
    );
  }

  const borrowButton = (
    <Button
      onClick={() => {
        if (!resolvedRecipient) {
          alert("Recipient address is not valid");
          return;
        }
        actWithPoofAccount(
          async (privateKey) => {
            try {
              await borrow(privateKey).then((txHash) => {
                if (txHash) {
                  setTxHash(txHash);
                  onBorrowClick();
                } else {
                  alert(
                    "No response from relayer. It's possible that the transaction still succeeded. Check your balance in 5 minutes before retrying the transaction."
                  );
                }
              });
            } catch (e: any) {
              if (e.response) {
                console.error(e.response.data.error);
              } else {
                console.error(e);
                alert(e.message);
              }
            }
          },
          () => {}
        );
      }}
      disabled={
        !resolvedRecipient ||
        (usingCustomRelayer && !customRelayer) ||
        isNaN(Number(amount)) ||
        Number(amount) > Number(maxBorrow)
      }
    >
      Borrow
    </Button>
  );

  const currencyOptions = deployments[network.chainId].map((entry, idx) => ({
    value: entry.pSymbol,
    label: entry.pSymbol,
  }));

  const params = useRequestParams();
  if (params.currency) {
    const currencyFromUrl = params.currency;
    const foundCurrency = deployments[network.chainId].find(
      (currency) => currency.symbol === currencyFromUrl
    );
    if (foundCurrency) {
      setCurrency(foundCurrency.pSymbol);
    }
  }

  const relayerSelectOptions = relayerOptions
    ? [
        ...relayerOptions.map((relayerOption) => ({
          value: relayerOption.url,
          label: `${relayerOption.url} - ${relayerOption.relayerFee}%`,
        })),
        { value: "custom", label: "Custom" },
      ]
    : [];

  return (
    <div>
      <Modal modalClosed={() => setSettingsOpened(false)} show={settingsOpened}>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text sx={{ fontWeight: [400], fontSize: [18], lineHeight: "20px" }}>
            Transaction settings
          </Text>
          <Button
            variant="ghost"
            onClick={() => setSettingsOpened(false)}
            sx={{
              margin: 0,
              padding: 0,
              height: "24px !important",
              color: "text",
            }}
          >
            <Close size={24} />
          </Button>
        </Flex>

        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Text variant="form" sx={{ mt: 4, mb: 2 }}>
            Relayer
          </Text>
          <StyledSelect
            value={relayer ? relayer.url : "custom"}
            options={relayerSelectOptions}
            onSelect={(o) => {
              if (o.value === "custom") {
                setCustomRelayer(undefined);
                setUsingCustomRelayer(true);
              } else {
                setSelectedRelayer(
                  relayerOptions?.find(
                    (relayerOption) => relayerOption.url === o.value
                  )
                );
                setUsingCustomRelayer(false);
              }
            }}
          ></StyledSelect>

          {usingCustomRelayer && (
            <>
              <Text variant="form" sx={{ mt: 4, mb: 2 }}>
                Custom relayer url
              </Text>
              <Input
                disabled={loading}
                name="customRelayer"
                type="text"
                value={customRelayerUrl}
                onChange={(event) => {
                  const relayerUrl = event.target.value;
                  setCustomRelayerUrl(relayerUrl);
                  onCustomRelayerUrlChange(relayerUrl);
                }}
                placeholder="Enter custom relayer address (protocol://...)"
              />
              <Text sx={{ mt: 2, color: "red" }} variant="form">
                {customRelayerError}
              </Text>
            </>
          )}
          <Button
            disabled={usingCustomRelayer && customRelayerError !== null}
            onClick={() => setSettingsOpened(false)}
            variant="submit"
          >
            Save
          </Button>
        </Flex>
      </Modal>

      <Box mb={3} mt={3}>
        <Grid columns={"1fr 2fr"} gap={20}>
          <Flex sx={{ flexDirection: "column" }} mb={1}>
            <Text variant="form">Currency</Text>
            <StyledSelect
              value={currency}
              options={currencyOptions}
              onSelect={(e) => setCurrency(e.value)}
            />
          </Flex>
          <Box>
            <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
              <Text variant="form">Amount</Text>
              <Text sx={{ whiteSpace: "nowrap" }} variant="form">
                <Link
                  sx={{ maxWidth: "100%" }}
                  onClick={() => {
                    if (hiddenBalances?.[0]) {
                      setAmount(maxBorrow);
                    }
                  }}
                >
                  Borrow max: {humanFriendlyNumber(maxBorrow)} {currency}
                </Link>
              </Text>
            </Flex>
            <Input
              sx={{ width: "100%" }}
              placeholder="Enter an amount to completeRequest"
              onChange={(e) => {
                const input = e.target.value;
                if (!isNaN(Number(input))) {
                  setAmount(input);
                }
              }}
              value={amount}
            />
          </Box>
        </Grid>
      </Box>

      <Text variant="form" sx={{ display: "block", mb: 1 }}>
        Recipient address
      </Text>
      <Input
        mb={2}
        disabled={loading}
        name="recipientAddress"
        type="text"
        onChange={(e) => setRecipient(e.target.value)}
        value={recipient}
        placeholder="Enter wallet address or .nom"
      />
      {recipient !== "" && !resolvedRecipient && (
        <>
          <Text sx={{ mt: 2, color: "red" }} variant="form">
            Invalid address, please try again.
          </Text>
          <br />
        </>
      )}
      <Button
        variant="ghost"
        onClick={() => setSettingsOpened(true)}
        sx={{ paddingLeft: "0", color: "purple400", lineHeight: "18px" }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Cog size={18} />
          <Text
            sx={{
              ml: "4px",
              color: "purple400",
              fontSize: "14px",
              lineHeight: "18px",
            }}
          >
            Show transaction settings
          </Text>
        </Flex>
      </Button>

      {breakpoint === Breakpoint.DESKTOP && (
        <Container mt={4}>
          {loading ? <ProofGenLoading /> : borrowButton}
        </Container>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <Box mt={6}>
            <SummaryTable
              lineItems={[
                {
                  label: "Borrow Amount",
                  value: `${humanFriendlyNumber(amount)} ${currency}`,
                },
                {
                  label: `Relayer Fee (${relayer?.relayerFee}%) + Gas`,
                  value: `${
                    relayerFee ? humanFriendlyNumber(relayerFee) : "-"
                  } ${currency}`,
                },
              ]}
              totalItem={{
                label: "Total",
                value: `${humanFriendlyNumber(finalBorrowAmount)} ${currency}`,
              }}
            />
          </Box>
          <ActionDrawer>
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <LabelWithBalance
                label="Total"
                amount={amount}
                currency={currency}
              />
              {loading ? <Spinner /> : borrowButton}
            </Flex>
          </ActionDrawer>
        </>
      )}
    </div>
  );
};
