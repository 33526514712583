import { useCallback } from "react";
import { AbiItem, toBN } from "web3-utils";
import LendingPoolAbi from "abis/LendingPool.json";
import { useAsyncState } from "hooks/useAsyncState";
import { WalletGlobal } from "hooks/useWallet";
import { LendingPool } from "generated/LendingPool";

export const useAaveApr = (
  lendingPoolAddress: string,
  reserveAddress: string
) => {
  const { web3 } = WalletGlobal.useContainer();

  const call = useCallback(async () => {
    const lendingPool = (new web3.eth.Contract(
      LendingPoolAbi as AbiItem[],
      lendingPoolAddress
    ) as unknown) as LendingPool;
    const currentLiquidityRate = (
      await lendingPool.methods.getReserveData(reserveAddress).call()
    )[3];
    return (
      Number(
        toBN(currentLiquidityRate).div(toBN("100000000000000000000")).toString()
      ) / 100000
    );
  }, [lendingPoolAddress, reserveAddress, web3.eth.Contract]);
  return useAsyncState(null, call);
};
