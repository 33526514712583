import React from "react";
import { useCompleteRequestApprove } from "hooks/actions/useCompleteRequestApprove";
import { Button, Text, Spinner } from "@theme-ui/components";
import { Box, Flex, Heading, Input, Select } from "theme-ui";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { deployments } from "@poofcash/poof-v2-kit";
import { useWeb3 } from "hooks/useWeb3";
import { useCompleteRequest } from "hooks/actions/useCompleteRequest";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useRequestParams } from "hooks/useRequestParams";
import { fromWei, toBN } from "web3-utils";
import { WalletGlobal } from "hooks/useWallet";
import { useTokenBalance } from "hooks/useTokenBalance";
import { SUPPORTED_NETWORKS } from "config";

interface IProps {
  onCompleteRequestClick?: () => void;
}

// pass props and State interface to Component class
export const PickCompleteRequest: React.FC<IProps> = ({
  onCompleteRequestClick,
}) => {
  const { connect, address } = WalletGlobal.useContainer();
  const { amount: amountWei, currency, data, chainId } = useRequestParams();
  const { setNetwork } = useWeb3();
  React.useEffect(() => {
    if (chainId) {
      const network = SUPPORTED_NETWORKS.find(
        (n) => n.chainId === Number(chainId)
      );
      if (network) {
        setNetwork(network);
      }
    }
  });
  const [connected, setConnected] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [balance] = useTokenBalance(currency, address);

  const { network } = useWeb3();
  const breakpoint = useBreakpoint();

  const approve = useCompleteRequestApprove();
  const completeRequest = useCompleteRequest();

  if (!currency || !amountWei || !data) {
    return <Heading as="h2">Invalid request</Heading>;
  }

  const amount = fromWei(amountWei); // TODO: Assumes wei

  const approved = balance.allowance.gt(toBN(amountWei));
  let button = (
    <Button
      onClick={() =>
        connect()
          .then(() => setConnected(true))
          .catch((e) => {
            console.error(e);
            alert(e.message);
          })
      }
    >
      Connect Wallet
    </Button>
  );
  if (connected) {
    button = (
      <Button
        onClick={async () => {
          try {
            setLoading(true);
            if (!approved) {
              await approve();
            }
            await completeRequest();
            onCompleteRequestClick && onCompleteRequestClick();
          } catch (e: any) {
            console.error(e);
            alert(e.message);
          } finally {
            setLoading(false);
          }
        }}
        disabled={Number(amount) === 0}
      >
        Complete Request
      </Button>
    );
  }

  return (
    <>
      <Text variant="form" sx={{ mb: 2 }}>
        Currency
      </Text>
      <Select mb={4} value={currency} disabled>
        {deployments[network.chainId].map((entry, idx) => {
          return (
            <option value={entry.symbol} key={idx}>
              {currency}
            </option>
          );
        })}
      </Select>

      <Box mb={4}>
        <Flex sx={{ width: "100%", justifyContent: "space-between" }} mb={1}>
          <Text variant="form">Amount</Text>
        </Flex>
        <Input
          sx={{ width: "100%" }}
          placeholder="Enter an amount to completeRequest"
          value={amount}
          disabled
        />
      </Box>

      {breakpoint === Breakpoint.DESKTOP && (
        <Box mt={4}>{loading ? <Spinner /> : button}</Box>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <Box mt={7}>
            <SummaryTable
              lineItems={[
                {
                  label: "Withdrawal Amount",
                  value: `${humanFriendlyNumber(amount)} ${currency}`,
                },
              ]}
              totalItem={{
                label: "Total",
                value: `${humanFriendlyNumber(amount)} ${currency}`,
              }}
            />
          </Box>
          <ActionDrawer>
            {loading ? (
              <Flex sx={{ justifyContent: "flex-end" }}>
                <Spinner />
              </Flex>
            ) : (
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelWithBalance
                  label="Total"
                  amount={amount}
                  currency={currency}
                />
                {button}
              </Flex>
            )}
          </ActionDrawer>
        </>
      )}
    </>
  );
};
