import React from "react";
import { DoRequest } from "pages/RequestPage/DesktopRequestPage/DoRequest";
import { RequestReceipt } from "pages/RequestPage/DesktopRequestPage/RequestReceipt";

enum RequestStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

const DesktopRequestPage = () => {
  const [requestStep, setRequestStep] = React.useState(RequestStep.DO);

  switch (requestStep) {
    case RequestStep.DO:
      return (
        <DoRequest
          onRequestClick={async () => {
            setRequestStep(RequestStep.RECEIPT);
          }}
        />
      );
    case RequestStep.RECEIPT:
      return (
        <RequestReceipt
          onDoneClick={() => {
            setRequestStep(RequestStep.DO);
          }}
        />
      );
  }
};

export default DesktopRequestPage;
