import { createReducer, createAction } from "@reduxjs/toolkit";

// Typings
export enum Page {
  HOME = "home",
  COMPLETE_REQUEST = "complete-request",
  DEPOSIT = "deposit",
  REQUEST = "request",
  SEND = "send",
  BORROW = "loans/borrow",
  REPAY = "loans/repay",
  INVITE = "invite",
  LOANS = "loans",

  SETUP = "account",
  SETUP_LOGIN = "account/login",
  SETUP_CREATE = "account/create",
}

interface GlobalState {
  readonly currentPage?: Page;
}

const initialState: GlobalState = {
  currentPage: Page.REQUEST,
};

// Actions
export const setCurrentPage = createAction<{ nextPage: Page }>(
  "global/setCurrentPage"
);

// Reducer
export default createReducer(initialState, (builder) =>
  builder.addCase(setCurrentPage, (state, action) => {
    const { nextPage } = action.payload;
    return { ...state, currentPage: nextPage };
  })
);
