import { atom } from "recoil";

export const borrowAmount = atom({
  key: "BORROW_AMOUNT",
  default: "0",
});

export const borrowCurrency = atom({
  key: "BORROW_CURRENCY",
  default: "pUSD",
});

export const borrowRecipient = atom({
  key: "BORROW_RECIPIENT",
  default: "",
});

export const borrowResolvedRecipient = atom<string | null>({
  key: "BORROW_RESOLVED_RECIPIENT",
  default: null,
});

export const borrowLoading = atom({
  key: "BORROW_LOADING",
  default: false,
});

export const borrowTxHash = atom({
  key: "BORROW_TX_HASH",
  default: "",
});
