export const shortenAccount = (
  account: string | null,
  truncation: number = 4
) => {
  if (!account) {
    return "0x????...????";
  }
  return (
    account.slice(0, truncation + 2) +
    ".." +
    account.slice(account.length - truncation - 1, account.length)
  );
};

export const enforceHex = (address?: string) => {
  if (!address) {
    return "";
  }
  if (address.startsWith("0x")) {
    return address;
  }
  return `0x${address}`;
};
