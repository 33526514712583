import React, { useState, useEffect, useContext } from "react";
import { Desktop } from "components/WalletSummary/Desktop";
// import { Mobile } from "components/WalletSummary/Mobile";
// import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { humanFriendlyNumber } from "utils/number";
import { humanFriendlyWei } from "utils/eth";
import { CurrenciesContext } from "contexts/currencies";

export const WalletSummary: React.FC<{
  assets: any;
}> = ({ assets }) => {
  // const breakpoint = useBreakpoint();
  const [balance, setBalance] = useState(0.0);
  // const [interest, setInterest] = useState(0.00);
  const [debtTotal, setDebtTotal] = useState(0.0);
  const [creditAvailable, setCreditAvailable] = useState(0.0);
  const { toUSD } = useContext(CurrenciesContext);

  useEffect(() => {
    if (assets) {
      let balance = 0.0;
      // let interest = 0.00; //TODO
      let debtTotal = 0.0;
      assets.forEach((a: any) => {
        balance += parseFloat(
          humanFriendlyNumber(toUSD(humanFriendlyWei(a.amount), a.symbol))
        );
        debtTotal += parseFloat(
          humanFriendlyNumber(toUSD(humanFriendlyWei(a.debt), a.symbol))
        );
      });
      setBalance(balance);
      setDebtTotal(debtTotal);
      setCreditAvailable(balance - debtTotal);
    }
  }, [assets]); //eslint-disable-line

  // if (breakpoint === Breakpoint.MOBILE) {
  //   return <Mobile />;
  // }

  return (
    <Desktop
      walletBalance={humanFriendlyNumber(balance)}
      debtTotal={humanFriendlyNumber(debtTotal)}
      creditAvailable={humanFriendlyNumber(creditAvailable)}
    />
  );
};
