import styled from "@emotion/styled/macro";
import { Box, Flex } from "@theme-ui/components";
import { getPercentage } from "../utils/number";

interface IProps {
  value: number | string;
  max: number | string;
  showPercentage?: boolean;
}

export const ProgressContainer = styled(Flex)`
  progress[value] {
    width: 100%;
    height: 6px;
    -webkit-appearance: none;
    appearance: none;

    ::-webkit-progress-bar {
      border-radius: 60px;
      background: rgba(113, 135, 252, 0.15);
    }

    ::-webkit-progress-value {
      background-color: #7187fc;
      border-bottom-left-radius: 60px;
      border-top-left-radius: 60px;
    }
  }
`;

export const ProgressBar: React.FC<IProps> = ({
  value,
  max,
  showPercentage = false,
}) => {
  return (
    <ProgressContainer sx={{ flexDirection: "column" }}>
      <progress value={value} max={max} />
      <Box hidden={!showPercentage} sx={{ color: "mediumGray", mt: "10px" }}>
        {getPercentage(value, max)}
      </Box>
    </ProgressContainer>
  );
};

ProgressBar.defaultProps = {
  showPercentage: true,
};
