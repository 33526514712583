import { useAaveApr } from "./useAaveApr";

export const useMaticAaveApr = (currency: string) => {
  const maticApr = useAaveApr(
    "0x8dff5e27ea6b7ac08ebfdf9eb090f32ee9a30fcf",
    "0x0d500b1d8e8ef31e21c99d1db9a6444d3adf1270"
  );
  if (currency === "MATIC") {
    return maticApr;
  }
  return [null, () => {}];
};
