const Loans = ({ size }) => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" widt={size} height={size} viewBox={`0 0 24 24`} >
        <path d="M4 21.0001H20.8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M4 18.6001H20.8" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M5.19922 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.59961 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M11.1992 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.5996 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.1992 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M19.5996 10.2001V16.2001" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M20.8 7.80011H4L11.7512 3.19052C11.9447 3.06613 12.1699 3 12.4 3C12.6301 3 12.8553 3.06613 13.0488 3.19052L20.8 7.80011Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default Loans;
