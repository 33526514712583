import { PoofAccountGlobal } from "hooks/poofAccount";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Page } from "state/global";

export const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const { poofAccount, privateKey } = PoofAccountGlobal.useContainer();
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!poofAccount || !privateKey) {
          return (
            <Redirect
              to={{
                pathname: `/${Page.SETUP}`,
                state: { from: location },
              }}
            />
          );
        }
        return children;
      }}
    />
  );
};
