import React from "react";
import { DoCompleteRequest } from "pages/CompleteRequestPage/DesktopCompleteRequestPage/DoCompleteRequest";
import { CompleteRequestReceipt } from "pages/CompleteRequestPage/DesktopCompleteRequestPage/CompleteRequestReceipt";

enum CompleteRequestStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

const DesktopCompleteRequestPage = () => {
  const [completeRequestStep, setCompleteRequestStep] = React.useState(
    CompleteRequestStep.DO
  );

  switch (completeRequestStep) {
    case CompleteRequestStep.DO:
      return (
        <DoCompleteRequest
          onCompleteRequestClick={async () => {
            setCompleteRequestStep(CompleteRequestStep.RECEIPT);
          }}
        />
      );
    case CompleteRequestStep.RECEIPT:
      return (
        <CompleteRequestReceipt
          onDoneClick={() => {
            setCompleteRequestStep(CompleteRequestStep.DO);
          }}
        />
      );
  }
};

export default DesktopCompleteRequestPage;
