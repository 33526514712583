import { useContractKit } from "@celo-tools/use-contractkit";
import React from "react";
import { useWeb3 } from "./useWeb3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import { createContainer } from "unstated-next";
import { DEFAULT_GAS_PRICE } from "config";

const useWallet = () => {
  const contractKit = useContractKit();
  const { web3, network } = useWeb3();
  const [address, setAddress] = React.useState<string | null>(null);
  const [gasPrice, setGasPrice] = React.useState(
    DEFAULT_GAS_PRICE[network.chainId]
  );
  const [gasCurrency, setGasCurrency] = React.useState("CELO");
  const isCelo = [42220, 44787].includes(network.chainId);
  React.useEffect(() => {
    web3.eth.getGasPrice().then(setGasPrice);
    if (isCelo) {
      setGasCurrency("CELO");
    } else {
      setGasCurrency(network.gasCurrency);
    }
  }, [isCelo, network.gasCurrency, web3.eth]);
  const connect = React.useCallback(async () => {
    const web3Modal = new Web3Modal({
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: {
            infuraId: "8043bb2cf99347b1bfadfb233c5325c0",
          },
        },
      },
    });
    web3Modal.clearCachedProvider();
    const provider = await web3Modal.connect();
    web3.setProvider(provider);
    const accounts = await web3.eth.getAccounts();
    setAddress(accounts[0]);
  }, [web3]);

  const getConnectedWeb3 = React.useCallback(async () => {
    if (isCelo) {
      const kit = await contractKit.getConnectedKit();
      return kit.web3;
    }
    return web3;
  }, [contractKit, isCelo, web3]);

  if (isCelo) {
    return {
      address: contractKit.address,
      connect: contractKit.connect,
      web3: contractKit.kit.web3,
      network: contractKit.network,
      getConnectedWeb3,
      gasPrice,
      gasCurrency,
    };
  }

  return {
    address,
    connect,
    web3,
    network,
    getConnectedWeb3,
    gasPrice,
    gasCurrency,
  };
};

export const WalletGlobal = createContainer(useWallet);
