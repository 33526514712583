import React from "react";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { toBN, toWei } from "web3-utils";
import { useRecoilState } from "recoil";
import {
  withdrawAmount,
  withdrawCurrency,
  withdrawLoading,
  withdrawResolvedRecipient,
} from "pages/WithdrawPage/state";
import { useRelayer } from "hooks/useRelayer";

export function useWithdraw() {
  const [amount] = useRecoilState(withdrawAmount);
  const [currency] = useRecoilState(withdrawCurrency);
  const [recipient] = useRecoilState(withdrawResolvedRecipient);
  const [, setLoading] = useRecoilState(withdrawLoading);
  const { relayer } = useRelayer(amount, currency);
  const { poofKit, progress } = PoofKitGlobal.useContainer();

  const withdraw = React.useCallback(
    async (privateKey: string) => {
      if (progress < 1) {
        alert("Poof kit still loading");
        return;
      }
      if (!relayer) {
        alert("Relayer is not defined");
        return;
      }
      if (!recipient) {
        alert("Recipient not resolved");
        return;
      }
      let txHash;
      try {
        setLoading(true);
        txHash = await poofKit.withdraw(
          privateKey,
          currency,
          toBN(toWei(amount)),
          toBN(0),
          recipient,
          relayer.url
        );
        return txHash;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [poofKit, currency, amount, recipient, relayer, progress, setLoading]
  );

  return withdraw;
}
