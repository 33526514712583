import React from "react";
import { DoWithdraw } from "pages/WithdrawPage/DesktopWithdrawPage/DoWithdraw";
import { WithdrawReceipt } from "pages/WithdrawPage/DesktopWithdrawPage/WithdrawReceipt";

enum WithdrawStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

export const DesktopWithdrawPage = () => {
  const [completeRequestStep, setWithdrawStep] = React.useState(
    WithdrawStep.DO
  );
  switch (completeRequestStep) {
    case WithdrawStep.DO:
      return (
        <DoWithdraw
          onWithdrawClick={() => {
            setWithdrawStep(WithdrawStep.RECEIPT);
          }}
        />
      );
    case WithdrawStep.RECEIPT:
      return (
        <WithdrawReceipt
          onDoneClick={() => {
            setWithdrawStep(WithdrawStep.DO);
          }}
        />
      );
  }
};
