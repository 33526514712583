import React from "react";
import { useRecoilState } from "recoil";
import {
  repayAmount,
  repayCurrency,
  RepayStage,
  repayStage,
  repayTxHash,
} from "pages/RepayPage/state";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { toBN, toWei } from "web3-utils";
import { WalletGlobal } from "hooks/useWallet";

export function useRepay() {
  const { getConnectedWeb3, address, gasPrice } = WalletGlobal.useContainer();
  const [, setTxHash] = useRecoilState(repayTxHash);
  const [amount] = useRecoilState(repayAmount);
  const [currency] = useRecoilState(repayCurrency);
  const [, setStage] = useRecoilState(repayStage);
  const { poofKit, progress } = PoofKitGlobal.useContainer();
  const { poofAccount, privateKey } = PoofAccountGlobal.useContainer();

  // TODO: TreeUpdate always should be used here
  const repay = React.useCallback(async () => {
    if (progress < 1) {
      alert("Poof kit still loading");
      return;
    }
    if (!poofAccount || !privateKey) {
      alert("No poof account or private key");
      return;
    }
    const poolMatch = await poofKit.poolMatch(currency);
    const web3 = await getConnectedWeb3();
    if (poolMatch) {
      try {
        setStage(RepayStage.PROVING);
        const txo = await poofKit.deposit(
          privateKey,
          currency,
          toBN(0),
          toBN(toWei(amount))
        );
        const params = {
          from: address || undefined,
          to: poolMatch.poolAddress,
          data: txo.encodeABI(),
          gasPrice,
        };
        const gas = await web3.eth.estimateGas(params);
        setStage(RepayStage.SIGNING);
        await web3.eth.sendTransaction({ ...params, gas }, (err, hash) => {
          if (err) throw err;
          setTxHash(hash);
          setStage(RepayStage.TXN_SENDING);
        });
      } catch (e) {
        throw e;
      }
    }
  }, [
    progress,
    poofAccount,
    privateKey,
    poofKit,
    currency,
    getConnectedWeb3,
    setStage,
    amount,
    address,
    gasPrice,
    setTxHash,
  ]);

  return repay;
}
