import React from "react";
import { Button, Text, Spinner } from "@theme-ui/components";
import { Box, Card, Flex, Input, Link } from "theme-ui";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { deployments } from "@poofcash/poof-v2-kit";
import { useRecoilState } from "recoil";
import { requestAmount, requestCurrency, requestLoading } from "../state";
import { useRequest } from "hooks/actions/useRequest";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { WalletGlobal } from "hooks/useWallet";
import { StyledSelect } from "../../../components/StyledSelect";

interface IProps {
  onRequestClick?: () => void;
}

export const PickRequest: React.FC<IProps> = () => {
  const { network } = WalletGlobal.useContainer();
  const [amount, setAmount] = useRecoilState(requestAmount);
  const [currency, setCurrency] = useRecoilState(requestCurrency);
  React.useEffect(() => {
    setCurrency(
      deployments[network.chainId].find((d) => d.symbol === "cUSD")?.symbol ||
        deployments[network.chainId][0].symbol
    );
  }, [network.chainId, setCurrency]);
  const [loading] = useRecoilState(requestLoading);
  const [link, setLink] = React.useState<string>();

  const breakpoint = useBreakpoint();

  const request = useRequest();

  const currencyOptions = deployments[network.chainId].map((entry, idx) => ({
    value: entry.symbol,
    label: entry.symbol,
  }));

  const button = (
    <Button
      onClick={() => {
        request()
          .then((link) => {
            setLink(link);
          })
          .catch((e) => {
            console.error(e);
            alert(e.message);
          });
      }}
      disabled={(() => {
        if (Number(amount) === 0) {
          return true;
        }
        return false;
      })()}
    >
      Generate link
    </Button>
  );

  return (
    <>
      <Text variant="form" sx={{ mb: 2 }}>
        Currency
      </Text>
      <StyledSelect
        options={currencyOptions}
        onSelect={(e) => setCurrency(e.value)}
      />
      <Box mb={4} mt={2}>
        <Flex sx={{ width: "100%", justifyContent: "space-between" }} mb={1}>
          <Text variant="form">Amount</Text>
        </Flex>
        <Input
          sx={{ width: "100%" }}
          placeholder="Enter an amount to request"
          onChange={(e) => {
            const input = e.target.value;
            if (!isNaN(Number(input))) {
              setAmount(input);
            }
          }}
          value={amount}
        />
      </Box>

      {breakpoint === Breakpoint.DESKTOP && (
        <Box mt={4}>{loading ? <ProofGenLoading /> : button}</Box>
      )}

      {link && (
        <Card mt={4}>
          <Text>
            Ta da!{" "}
            <Link target="_blank" rel="noopener noreferrer" href={link}>
              Share this link
            </Link>
          </Text>
        </Card>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <ActionDrawer>
            {loading ? (
              <Flex sx={{ justifyContent: "flex-end" }}>
                <Spinner />
              </Flex>
            ) : (
              <Flex
                sx={{
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <LabelWithBalance
                  label="Total"
                  amount={amount}
                  currency={currency}
                />
                {button}
              </Flex>
            )}
          </ActionDrawer>
        </>
      )}
    </>
  );
};
