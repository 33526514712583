import React from "react";
import MobileRequestPage from "pages/RequestPage/MobileRequestPage";
import DesktopRequestPage from "pages/RequestPage/DesktopRequestPage";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

const RequestPage: React.FC = () => {
  const breakpoint = useBreakpoint();

  if (breakpoint === Breakpoint.MOBILE) {
    return <MobileRequestPage />;
  }

  return <DesktopRequestPage />;
};

export default RequestPage;
