import React from "react";
import { Card, Flex, Text, Button, Box } from "@theme-ui/components";
import styled from "@emotion/styled";
import {
  Deposit as DepositIcon,
  Loans as LoansIcon,
  Repay as RepayIcon,
  Request as RequestIcon,
  Withdrawal as SendIcon,
} from "assets/icons/index";
import { Link } from "react-router-dom";
import { Page } from "state/global";
import { HomeButton } from "../HomeButton";

export const BorderedBox = styled(Card)({
  border: "1px solid #ECE6FE",
  boxSizing: "border-box",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
});

const Item = styled(Box)({
  flex: 1,
});

//MOVE TO THEME-UI VARIANT
export const Label = styled(Box)({
  fontSize: "14px",
  fontWeight: "normal",
  lineHeight: "20px",
  color: "#757575",
  backgroundColor: "none",
  marginBottom: "8px",
});

//todo: EXPORT!
export const Money = styled(Box)({
  fontWeight: 400,
  color: "#292B30",
  marginBottom: "18px",
});

const NoStyleLink = styled(Link)({
  textDecoration: "none",
  color: "var(--theme-ui-colors-primaryButtonColor)",
});

export const Desktop: React.FC<{
  walletBalance: string;
  debtTotal: string;
  creditAvailable: string;
}> = ({ walletBalance, debtTotal, creditAvailable }) => {
  return (
    <BorderedBox sx={{ padding: ["0", "26px 24px", "26px 24px"] }}>
      <Flex sx={{ width: "100%", flexDirection: ["column", "row", "row"] }}>
        <Item
          sx={{
            // gridRow: "1",
            //             // gridColumnStart: "1",
            //             // gridColumnEnd: ["3",
            //             "1", "1"],
            borderBottom: ["1px solid #ECE6FE", "none", "none"],
            borderRight: ["none", "1px solid #ECE6FE"],
            padding: "16px",
          }}
        >
          <Label>Wallet Balance</Label>
          <Money sx={{ fontSize: "36px", lineHeight: "49px" }}>
            ${walletBalance}
          </Money>
          <div>
            <NoStyleLink to={Page.DEPOSIT}>
              <Button variant="primary">
                <DepositIcon size={20} /> Deposit
              </Button>
            </NoStyleLink>
          </div>
        </Item>
        <Item
          sx={{
            borderRight: ["none"],
            padding: "16px",
          }}
        >
          <Label>Loans</Label>
          <Money
            style={{ color: "#7187FC" }}
            sx={{
              fontSize: ["24px", "24px", "36px"],
              lineHeight: ["33px", "33px", "48px"],
            }}
          >
            ${debtTotal}
          </Money>
          <Flex
            sx={{
              justifyItems: "flex-start",
              alignItems: ["flex-start", "flex-start", "center"],
            }}
          >
            <NoStyleLink to={Page.REPAY}>
              <Button variant="primary" sx={{ mr: 3 }}>
                <RepayIcon size={20} /> Repay
              </Button>
            </NoStyleLink>
            <Text sx={{ display: ["none", "none", "block"], color: "#7187FC" }}>
              Available Credit: ${creditAvailable}
            </Text>
          </Flex>
        </Item>
        <Item
          sx={{
            gridColumnStart: "1",
            gridColumnEnd: "3",
            borderTop: ["1px solid #ECE6FE", "none", "none"],
            padding: "16px",
            flex: ["1", "1", "0"],
          }}
        >
          <Flex
            sx={{
              mb: 3,
              display: ["flex", "flex", "none"],
              justifyContent: "space-around",
            }}
          >
            <HomeButton
              to={Page.SEND}
              label={"Send"}
              icon={<SendIcon size={28} />}
            />
            <HomeButton
              to={Page.REQUEST}
              label={"Request"}
              icon={<RequestIcon size={28} />}
            />
            <HomeButton
              to={Page.BORROW}
              label={"Borrow"}
              icon={<LoansIcon size={28} />}
            />
          </Flex>
        </Item>
      </Flex>
    </BorderedBox>
  );
};
