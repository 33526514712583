import React from "react";
import { toBN, toWei } from "web3-utils";
import { useRecoilState } from "recoil";
import { toastTx } from "utils/toastTx";
import { PoofKit } from "@poofcash/poof-v2-kit";
import {
  depositAmount,
  depositCurrency,
  DepositStage,
  depositStage,
} from "pages/DepositPage/state";
import { WalletGlobal } from "hooks/useWallet";

export const useDepositApprove = () => {
  const [amount] = useRecoilState(depositAmount);
  const [currency] = useRecoilState(depositCurrency);
  const [, setStage] = useRecoilState(depositStage);
  const { getConnectedWeb3, address, gasPrice } = WalletGlobal.useContainer();

  const approve = React.useCallback(async (): Promise<void> => {
    if (!amount || !currency) {
      alert("Invalid deposit");
      return;
    }
    const web3 = await getConnectedWeb3();
    const poofKit = new PoofKit(web3);
    const poolMatch = await poofKit.poolMatch(currency);
    if (poolMatch) {
      try {
        const txo = await poofKit.approve(
          currency,
          toBN(toWei(amount)).mul(toBN(1001)).div(toBN(1000)) // Add 0.1% buffer
        );
        const params = {
          from: address || undefined,
          to: poolMatch.tokenAddress,
          data: txo.encodeABI(),
          gasPrice,
        };
        const gas = await txo.estimateGas(params);
        setStage(DepositStage.SIGNING);
        const tx = await web3.eth.sendTransaction(
          {
            ...params,
            gas,
          },
          (err) => {
            if (err) throw err;
            setStage(DepositStage.TXN_SENDING);
          }
        );
        setStage(DepositStage.TXN_SENDING);
        tx.transactionHash && toastTx(tx.transactionHash);
      } catch (error) {
        throw error;
      }
    }
  }, [amount, currency, getConnectedWeb3, setStage, address, gasPrice]);

  return approve;
};
