import { deployments } from "@poofcash/poof-v2-kit";
import React from "react";
import { toBN } from "web3-utils";
import { PoofAccountGlobal } from "./poofAccount";
import { useAsyncState } from "./useAsyncState";
import { PoofKitGlobal } from "./usePoofKit";
import { useWeb3 } from "./useWeb3";

export const useHiddenBalances = (currency?: string) => {
  const { network } = useWeb3();
  const { privateKey } = PoofAccountGlobal.useContainer();
  const { poofKit } = PoofKitGlobal.useContainer();
  const call = React.useCallback(async () => {
    if (!privateKey) {
      return null;
    }
    const pools = deployments[network.chainId].filter((pool) =>
      currency
        ? pool.symbol.toLowerCase() === currency?.toLowerCase() ||
          pool.pSymbol.toLowerCase() === currency?.toLowerCase()
        : true
    );
    const accounts = await Promise.all(
      pools.map((pool) =>
        poofKit.getLatestAccount(privateKey, pool.symbol.toLowerCase())
      )
    );
    const unitPerUnderlyings = await Promise.all(
      pools.map((pool) => poofKit.unitPerUnderlying(pool.symbol.toLowerCase()))
    );
    return accounts.map((acc, i) => {
      return {
        ...pools[i],
        ...(acc || {}),
        amount: acc ? acc.amount.div(unitPerUnderlyings[i]) : toBN(0),
        debt: acc ? acc.debt : toBN(0),
      };
    });
  }, [privateKey, network.chainId, currency, poofKit]);

  return useAsyncState(null, call);
};
