import React from "react";
import { useRecoilState } from "recoil";
import {
  depositAmount,
  depositCurrency,
  DepositStage,
  depositStage,
  depositTxHash,
} from "pages/DepositPage/state";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { toBN, toWei } from "web3-utils";
import { WalletGlobal } from "hooks/useWallet";

export function useDeposit() {
  const { address, getConnectedWeb3, gasPrice } = WalletGlobal.useContainer();
  const [, setTxHash] = useRecoilState(depositTxHash);
  const [amount] = useRecoilState(depositAmount);
  const [currency] = useRecoilState(depositCurrency);
  const [, setStage] = useRecoilState(depositStage);
  const { poofKit, progress } = PoofKitGlobal.useContainer();
  const { poofAccount, privateKey } = PoofAccountGlobal.useContainer();

  // TODO(bl): TreeUpdate always should be used here
  const deposit = React.useCallback(async () => {
    if (progress < 1) {
      alert("Poof kit still loading");
      return;
    }
    if (!poofAccount || !privateKey) {
      alert("No poof account or private key");
      return;
    }
    const poolMatch = await poofKit.poolMatch(currency);
    const web3 = await getConnectedWeb3();
    if (poolMatch) {
      setStage(DepositStage.PROVING);
      try {
        const txo = await poofKit.deposit(
          privateKey,
          currency,
          toBN(toWei(amount)),
          toBN(0)
        );
        const params = {
          from: address || undefined,
          to: poolMatch.poolAddress,
          data: txo.encodeABI(),
          gasPrice,
          value: poolMatch.tokenAddress
            ? 0
            : toBN(toWei(amount)).mul(toBN(100001)).div(toBN(100000)), // 0.001% buffer
        };
        const gas = await txo.estimateGas(params);
        setStage(DepositStage.SIGNING);
        await web3.eth.sendTransaction({ ...params, gas }, (err, hash) => {
          if (err) throw err;
          setTxHash(hash);
          setStage(DepositStage.TXN_SENDING);
        });
      } catch (e) {
        throw e;
      }
    }
  }, [
    progress,
    poofAccount,
    privateKey,
    poofKit,
    currency,
    getConnectedWeb3,
    setStage,
    amount,
    address,
    gasPrice,
    setTxHash,
  ]);

  return deposit;
}
