import React, { useEffect, useState, useContext } from "react";
import { Desktop } from "components/Assets/Desktop";
import { Mobile } from "components/Assets/Mobile";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { logo } from "assets/icons";
import { humanFriendlyNumber, toBN } from "utils/number";
import { humanFriendlyWei } from "utils/eth";
import { CurrenciesContext } from "contexts/currencies";

export const Assets: React.FC<{
  assets: any;
}> = ({ assets }) => {
  interface IHash {
    [key: string]: any;
  }
  const [data, setData] = useState<IHash>({});
  const { toUSD } = useContext(CurrenciesContext);

  useEffect(() => {
    if (assets) {
      let d: IHash = [];
      let total_usd = 0.0;
      assets.forEach((a: any) => {
        if (d[a.symbol] === undefined) {
          d[a.symbol] = {
            asset_pcnt: 0,
            credit: 0,
            credit_usd: 0,
          };
        }
        d[a.symbol] = {
          ...d[a.symbol],
          ...a, //DATA from wallets
          icon: logo(a.symbol, 40),
          amount_formatted: humanFriendlyWei(a.amount),
          amount_usd: humanFriendlyNumber(
            toUSD(humanFriendlyWei(a.amount), a.symbol)
          ),
          credit_available: humanFriendlyWei(toBN(a.amount).sub(toBN(a.debt))),
        };
        total_usd += parseFloat(d[a.symbol].amount_usd);
      });
      Object.entries(d).forEach((v: any) => {
        let [key, value] = v;
        d[key] = {
          ...value,
          asset_pcnt:
            total_usd <= 0
              ? 0
              : ((parseFloat(value.amount_usd) / total_usd) * 100).toFixed(2),
        };
      });
      setData(d);
    }
  }, [assets, toUSD]); //eslint-disable-line
  const breakpoint = useBreakpoint();

  if (breakpoint === Breakpoint.MOBILE) {
    return <Mobile assets={Object.entries(data).map((a) => a[1])} />;
  }

  return <Desktop assets={Object.entries(data).map((a) => a[1])} />;
};
