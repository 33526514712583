import React from "react";
import { toBN } from "web3-utils";
import { toastTx } from "utils/toastTx";
import { useRequestParams } from "hooks/useRequestParams";
import { PoofKit } from "@poofcash/poof-v2-kit";
import { WalletGlobal } from "hooks/useWallet";

export const useCompleteRequestApprove = () => {
  const { amount, currency } = useRequestParams();
  const { getConnectedWeb3, gasPrice, address } = WalletGlobal.useContainer();

  const approve = React.useCallback(async (): Promise<void> => {
    if (!amount || !currency) {
      alert("Invalid completeRequest request");
      return;
    }
    const web3 = await getConnectedWeb3();
    const poofKit = new PoofKit(web3);
    const poolMatch = await poofKit.poolMatch(currency);
    if (poolMatch) {
      try {
        const txo = await poofKit.approve(
          currency,
          toBN(amount).mul(toBN(10001)).div(toBN(10000)) // Add 0.01% buffer
        );
        const tx = await web3.eth.sendTransaction({
          from: address || undefined,
          to: poolMatch.tokenAddress,
          data: txo.encodeABI(),
          gasPrice,
        });
        tx.transactionHash && toastTx(tx.transactionHash);
      } catch (error) {
        throw error;
      }
    }
  }, [amount, currency, getConnectedWeb3, address, gasPrice]);

  return approve;
};
