import { useQuery } from "hooks//useQuery";

export const useRequestParams = () => {
  const query = useQuery();
  const amount = query.get("amount");
  const currency = query.get("currency");
  const data = query.get("data");
  const chainId = query.get("chainId");
  return { amount, currency, data, chainId };
};
