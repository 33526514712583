import React from "react";
import { Box, Card, Flex, Grid } from "@theme-ui/components";
import { Money } from "../../../components/WalletSummary/Desktop";
import { Link } from "react-router-dom";
import { Button } from "theme-ui";
import { Loans as LoansIcon } from "../../../assets/icons";
import { Repay as RepayIcon } from "../../../assets/icons";
import { ProgressBar } from "../../../components/ProgressBar";
import styled from "@emotion/styled/macro";

const StyledLink = styled(Link)`
  flex: 1;

  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const SingleCreditSummary: React.FC<{ asset: any }> = ({ asset }) => {
  return (
    <Card sx={{ variant: "cards.bordered" }} mb={3}>
      <Grid columns={["1"]} gap={0} sx={{ height: "100%" }}>
        <Flex
          sx={{
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
          mb={4}
        >
          <Flex
            sx={{
              alignItems: "center",
              justifyContent: ["space-between", "flex-start", "flex-start"],
              width: "100%",
            }}
          >
            <Flex sx={{ alignItems: "center" }}>{asset.icon}</Flex>
            <Flex sx={{ flexDirection: "column", height: 40 }} ml={8}>
              <Box sx={{ variant: "box.responsiveLabel" }}>
                Remaining credit
              </Box>
              <Flex sx={{ flexGrow: 2 }}>
                <Money sx={{ fontSize: "16px" }}>
                  {asset.credit_available}
                </Money>
                <Box sx={{ fontWeight: [700] }} ml={2}>
                  {asset.symbol}
                </Box>
              </Flex>
            </Flex>
            <Flex
              sx={{
                flexDirection: "column",
                height: 40,
                alignItems: ["flex-end", "flex-start", "flex-start"],
              }}
              ml={["auto", "8px", "90px"]}
            >
              <Box sx={{ variant: "box.responsiveLabel" }}>Balance</Box>
              <Money>${asset.amount_debt_usd}</Money>
            </Flex>
          </Flex>
          <Box
            sx={{
              alignItems: "center",
              justifyItems: "center",
              display: ["none", "flex", "flex"],
            }}
          >
            {Number(asset.amount_formatted) > 0 && (
              <Link to={`/loans/borrow?currency=${asset.symbol}`}>
                <Button variant="primary" sx={{ display: "inline-block" }}>
                  <Grid
                    columns={"1fr 1fr"}
                    gap={"8px"}
                    sx={{ alignItems: "center" }}
                  >
                    <LoansIcon size={20} />
                    Borrow
                  </Grid>
                </Button>
              </Link>
            )}
            {Number(asset.credit_available) > 0 && (
              <Link to={`/loans/repay?currency=${asset.pSymbol}`}>
                <Button
                  variant="primary"
                  sx={{ display: "inline-block" }}
                  ml={1}
                >
                  <Grid
                    columns={"1fr 1fr"}
                    gap={"8px"}
                    sx={{ alignItems: "center" }}
                  >
                    <RepayIcon size={20} />
                    Repay
                  </Grid>
                </Button>
              </Link>
            )}
          </Box>
        </Flex>
        <Box>
          <ProgressBar value={asset.amount_debt} max={asset.credit_available} />
        </Box>
        <Box
          sx={{
            alignItems: "center",
            justifyItems: "center",
            display: ["flex", "none", "none"],
          }}
        >
          {Number(asset.amount_formatted) > 0 && (
            <StyledLink
              to={`/loans/repay?currency=${asset.pSymbol}`}
              style={{ flex: "1" }}
            >
              <Button
                variant="primary"
                sx={{ display: "inline-block", width: "100%" }}
              >
                <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
                  <RepayIcon size={20} />
                  <Box ml={2}>Repay</Box>
                </Flex>
              </Button>
            </StyledLink>
          )}
          {Number(asset.credit_available) > 0 && (
            <StyledLink
              to={`/loans/borrow?currency=${asset.symbol}`}
              style={{ flex: "1" }}
            >
              <Button
                variant="primary"
                sx={{ display: "inline-block", width: "100%" }}
              >
                <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
                  <LoansIcon size={20} />
                  <Box ml={2}>Borrow</Box>
                </Flex>
              </Button>
            </StyledLink>
          )}
        </Box>
      </Grid>
    </Card>
  );
};

export default SingleCreditSummary;
