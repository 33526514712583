import { useAaveApr } from "./useAaveApr";

export const useAvaxAaveApr = (currency: string) => {
  const avaxApr = useAaveApr(
    "0x4F01AeD16D97E3aB5ab2B501154DC9bb0F1A5A2C",
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7"
  );
  if (currency === "AVAX") {
    return avaxApr;
  }
  return [null, () => {}];
};
