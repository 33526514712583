import { atom } from "recoil";

export const repayAmount = atom({
  key: "REPAY_AMOUNT",
  default: "0",
});

export const repayCurrency = atom({
  key: "REPAY_CURRENCY",
  default: "pUSD",
});

export enum RepayStage {
  START,
  SIGNING,
  TXN_SENDING,
  PROVING,
}

export const repayStage = atom({
  key: "REPAY_STAGE",
  default: RepayStage.START,
});

export const repayTxHash = atom({
  key: "REPAY_TX_HASH",
  default: "",
});
