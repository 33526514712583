import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { BlockscoutTxLink } from "components/Links";
import moment from "moment";
import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { shortenAccount } from "utils/account";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import {
  borrowAmount,
  borrowCurrency,
  borrowRecipient,
  borrowTxHash,
} from "../state";

interface IProps {
  onDoneClick: () => void;
}

export const BorrowReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const [amount] = useRecoilState(borrowAmount);
  const [currency] = useRecoilState(borrowCurrency);
  const [recipient] = useRecoilState(borrowRecipient);
  const [txHash] = useRecoilState(borrowTxHash);
  const { relayerFee } = useRelayer(amount, currency);

  const finalBorrowAmount = Number(amount) + Number(relayerFee);

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
        You have successfully borrowed {currency}.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <SummaryTable
        title="Transaction"
        lineItems={[
          {
            label: "Time completed",
            value: moment().format("h:mm a"),
          },
          {
            label: "Account",
            value: shortenAccount(recipient),
          },
          {
            label: "Borrow amount",
            value: `${humanFriendlyNumber(amount)} ${currency}`,
          },
        ]}
        totalItem={{
          label: "Est. Borrow",
          value: `${humanFriendlyNumber(finalBorrowAmount)} ${currency}`,
        }}
      />

      <ActionDrawer>
        <Flex sx={{ justifyContent: "space-between" }}>
          <LabelWithBalance
            label="Withdrew"
            amount={finalBorrowAmount}
            currency={currency}
          />
          <Button
            onClick={() => {
              onDoneClick();
            }}
            variant="done"
          >
            Done
          </Button>
        </Flex>
      </ActionDrawer>
    </Container>
  );
};
