import { useAaveApr } from "./useAaveApr";

export const useEthAaveApr = (currency: string) => {
  const avaxApr = useAaveApr(
    "0x7d2768de32b0b80b7a3454c06bdac94a69ddc7a9",
    "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
  );
  if (currency === "ETH") {
    return avaxApr;
  }
  return [null, () => {}];
};
