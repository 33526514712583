import { atom } from "recoil";

export const completeRequestLoading = atom({
  key: "COMPLETE_REQUEST_LOADING",
  default: false,
});

export const completeRequestTxHash = atom({
  key: "COMPLETE_REQUEST_TX_HASH",
  default: "",
});
