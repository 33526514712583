import { atom } from "recoil";

export const withdrawAmount = atom({
  key: "WITHDRAW_AMOUNT",
  default: "0",
});

export const withdrawCurrency = atom({
  key: "WITHDRAW_CURRENCY",
  default: "cUSD",
});

export const withdrawRecipient = atom({
  key: "WITHDRAW_RECIPIENT",
  default: "",
});

export const withdrawResolvedRecipient = atom<string | null>({
  key: "WITHDRAW_RESOLVED_RECIPIENT",
  default: null,
});

export const withdrawLoading = atom({
  key: "WITHDRAW_LOADING",
  default: false,
});

export const withdrawTxHash = atom({
  key: "WITHDRAW_TX_HASH",
  default: "",
});
