import { atom } from "recoil";

export const depositAmount = atom({
  key: "DEPOSIT_AMOUNT",
  default: "0",
});

export const depositCurrency = atom({
  key: "DEPOSIT_CURRENCY",
  default: "cUSD",
});

export enum DepositStage {
  START,
  SIGNING,
  TXN_SENDING,
  PROVING,
}

export const depositStage = atom({
  key: "DEPOSIT_STAGE",
  default: DepositStage.START,
});

export const depositTxHash = atom({
  key: "DEPOSIT_TX_HASH",
  default: "",
});
