import { X } from "phosphor-react";
import { Box, Button, Flex, Text } from "theme-ui";
import { SUPPORTED_NETWORKS } from "config";
import { useWeb3 } from "hooks/useWeb3";

interface Props {
  onClose?: () => any;
}

export const NetworkDetails: React.FC<Props> = ({ onClose }) => {
  const { setNetwork } = useWeb3();
  return (
    <Box>
      <Flex sx={{ justifyContent: "space-between", mb: 3 }}>
        <Text sx={{ fontSize: 18 }}>Networks</Text>
        {onClose && <X size={20} onClick={onClose} />}
      </Flex>
      {SUPPORTED_NETWORKS.map((network, idx) => {
        return (
          <Button
            key={idx}
            sx={{ whiteSpace: "nowrap" }}
            variant="secondary"
            mr={2}
            mb={1}
            onClick={() => {
              setNetwork(network);
            }}
          >
            {network.name}
          </Button>
        );
      })}
    </Box>
  );
};
