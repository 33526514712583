import React from "react";
import { DoBorrow } from "pages/BorrowPage/DesktopBorrowPage/DoBorrow";
import { BorrowReceipt } from "pages/BorrowPage/DesktopBorrowPage/BorrowReceipt";

enum BorrowStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

export const DesktopBorrowPage = () => {
  const [completeRequestStep, setBorrowStep] = React.useState(BorrowStep.DO);
  switch (completeRequestStep) {
    case BorrowStep.DO:
      return (
        <DoBorrow
          onBorrowClick={() => {
            setBorrowStep(BorrowStep.RECEIPT);
          }}
        />
      );
    case BorrowStep.RECEIPT:
      return (
        <BorrowReceipt
          onDoneClick={() => {
            setBorrowStep(BorrowStep.DO);
          }}
        />
      );
  }
};
