import { DECIMAL_PRECISION } from "config";
import BN from "bn.js";

export const humanFriendlyNumber = (v: number | string) => {
  const num = Number(v);
  if (num === 0) {
    return "0";
  }
  const smallest = Math.pow(10, -DECIMAL_PRECISION);
  if (num < smallest) {
    return `<${smallest.toFixed(DECIMAL_PRECISION)}`;
  }

  const formatted = num.toLocaleString(undefined, {
    minimumFractionDigits: 20,
  });
  const decimalIdx = formatted.indexOf(".");
  if (decimalIdx === -1) {
    return formatted;
  }
  return formatted.slice(0, decimalIdx + 1 + DECIMAL_PRECISION);
};

export const toBN = (v: number | string) => {
  return new BN(v);
};

export const parseNumber = (value: string): number => {
  if (value === undefined) {
    return 0;
  } else {
    return parseFloat(value);
  }
};

export const getPercentage = (value: number | string, max: number | string) => {
  if (value === 0 || max === 0 || value === "0" || max === "0") return "0%";
  else {
    return `${Math.floor((Number(value) / Number(max)) * 100)}%`;
  }
};
