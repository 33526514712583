import React from "react";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { useHistory, Link } from "react-router-dom";
import { Button, Text, Input, Box, Link as ThemeLink } from "theme-ui";
import { isPrivateKey } from "utils/eth";
import { toast } from "react-toastify";
import { Page } from "state/global";
import { DividerWithText } from "components/DividerWithText";

export const PickLogin: React.FC = () => {
  const privateKeyInput = React.useRef<any>(null);
  const passwordInput = React.useRef<any>(null);
  const {
    unlockPoofAccount,
    savePoofAccount,
    poofAccount,
    disconnectPoofAccount,
  } = PoofAccountGlobal.useContainer();
  const history = useHistory();

  if (poofAccount) {
    return (
      <form
        onSubmit={(e) => {
          const password = passwordInput?.current.value ?? "";
          const res = unlockPoofAccount(password);
          if (!res) {
            toast("Incorrect password. Please try again.");
          } else {
            history.push(`/${Page.HOME}`);
          }
          e.preventDefault();
        }}
      >
        <Text variant="form" sx={{ mb: 2 }}>
          Password
        </Text>
        <Input
          name="password"
          type="password"
          ref={passwordInput}
          placeholder="Enter password"
          autoComplete="off"
          mb={2}
          autoFocus
        />
        <Button
          variant="primary"
          sx={{ width: "100%" }}
          mt={2}
          mb={3}
          type="submit"
        >
          Log in
        </Button>
        <Button
          variant="secondary"
          onClick={disconnectPoofAccount}
          sx={{ width: "100%" }}
        >
          <ThemeLink>Log out</ThemeLink>
        </Button>
      </form>
    );
  }

  return (
    <form
      onSubmit={(e) => {
        const privateKey = privateKeyInput?.current.value;
        if (!isPrivateKey(privateKey)) {
          toast("Invalid Poof private key. Please try again.");
        } else {
          savePoofAccount(privateKey, () => {
            history.push(`/${Page.HOME}`);
          });
        }
        e.preventDefault();
      }}
    >
      <Text variant="form" sx={{ mb: 2 }}>
        Poof private key
      </Text>
      <Input
        name="password"
        type="password"
        ref={privateKeyInput}
        placeholder="Enter your Poof private key here"
        autoComplete="off"
        mb={2}
      />
      <Button
        variant="primary"
        sx={{ width: "100%" }}
        mt={2}
        mb={4}
        type="submit"
      >
        Log in
      </Button>
      <DividerWithText text="or" />
      <Box mt={4}>
        <Link to={Page.SETUP_CREATE}>
          <Button sx={{ width: "100%" }} variant="secondary">
            Create a Poof wallet
          </Button>
        </Link>
      </Box>
    </form>
  );
};
