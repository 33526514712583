import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import moment from "moment";
import { BlockscoutTxLink } from "components/Links";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { shortenAccount } from "utils/account";
import { completeRequestTxHash } from "../state";
import { useRecoilState } from "recoil";
import { useRequestParams } from "hooks/useRequestParams";
import { fromWei } from "web3-utils";
import { NETWORK_COST } from "config";
import { WalletGlobal } from "hooks/useWallet";

interface IProps {
  onDoneClick: () => void;
}

export const CompleteRequestReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const { amount: amountWei, currency } = useRequestParams();
  const { gasCurrency, address } = WalletGlobal.useContainer();
  const [txHash] = useRecoilState(completeRequestTxHash);

  if (!amountWei || !currency) {
    return <Text>Invalid completeRequest request</Text>;
  }
  const amount = fromWei(amountWei); // TODO: Assumes wei

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regular">
        You have fulfilled the request.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <SummaryTable
        title="Transaction"
        lineItems={[
          {
            label: "Time completed",
            value: moment().format("h:mm a"),
          },
          {
            label: "Account",
            value: shortenAccount(address),
          },
          {
            label: "Est. total amount",
            value:
              currency === gasCurrency
                ? `${humanFriendlyNumber(
                    Number(amount) + Number(NETWORK_COST)
                  )} ${gasCurrency}`
                : `${humanFriendlyNumber(
                    amount
                  )} ${currency} + ${humanFriendlyNumber(
                    NETWORK_COST
                  )} ${gasCurrency}`,
          },
        ]}
        totalItem={{
          label: "Completed Request",
          value: `${humanFriendlyNumber(amount)} ${currency}`,
        }}
      />

      <ActionDrawer>
        <Flex sx={{ justifyContent: "space-between" }}>
          <LabelWithBalance
            label="Completed Request"
            amount={amount}
            currency={currency}
          />
          <Button
            onClick={() => {
              onDoneClick();
            }}
            variant="done"
          >
            Done
          </Button>
        </Flex>
      </ActionDrawer>
    </Container>
  );
};
