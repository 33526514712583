import React from "react";
import { useLocation } from "react-router-dom";
import { Box, Container, Flex, Link, Text } from "theme-ui";
import { Logo } from "components/Logo";
import { AccountProfile } from "components/AccountProfile";
import { Page } from "state/global";
import { StyledLink } from "components/StyledLink";

const HeaderLink: React.FC<{ pages: string[] }> = ({ pages, children }) => {
  const location = useLocation();
  let selected = false;
  const link = pages[0];
  pages.forEach((page) => {
    if (location.pathname.includes(page)) {
      selected = true;
    }
  });
  return (
    <Box mr={2}>
      <StyledLink to={link}>
        <Text
          sx={{
            color: selected ? "primary" : "text",
            borderBottom: selected ? "2px solid" : "none",
            mx: 1,
            pb: 1,
          }}
          variant="subtitle"
        >
          {children}
        </Text>
      </StyledLink>
    </Box>
  );
};

export const DesktopHeader: React.FC = () => {
  return (
    <>
      <Container sx={{ width: "auto" }}>
        <Flex
          sx={{
            mb: 2,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Flex
            sx={{
              flex: 1,
              justifyContent: "flex-start",
            }}
          >
            <Logo />
          </Flex>
          <Flex
            sx={{
              alignItems: "center",
              flex: 1,
            }}
          >
            <HeaderLink pages={[`/${Page.HOME}`]}>Portfolio</HeaderLink>
            <HeaderLink pages={[`/${Page.SEND}`, Page.REQUEST]}>
              Send & Request
            </HeaderLink>
            {/* <HeaderLink page={Page.REQUEST}>Request</HeaderLink>
            <HeaderLink page={Page.SEND}>Send</HeaderLink> */}
            <HeaderLink pages={[`/${Page.LOANS}`]}>Loans</HeaderLink>
            {/* <HeaderLink page={Page.INVITE}>Invite</HeaderLink> */}
            <Link
              href="https://docs.poof.cash"
              target="_blank"
              rel="noopener noreferrer"
              style={{ textDecoration: "none" }}
            >
              <Text
                sx={{
                  color: "text",
                  borderBottom: "none",
                  mx: 1,
                  pb: 1,
                  ":hover": {
                    borderBottom: "2px solid",
                  },
                }}
                variant="subtitle"
              >
                Docs
              </Text>
            </Link>
          </Flex>
          <Flex
            sx={{ alignItems: "center", justifyContent: "flex-end", flex: 1 }}
          >
            <AccountProfile />
          </Flex>
        </Flex>
      </Container>
    </>
  );
};
