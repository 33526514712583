import React from "react";
import { MobileBorrowPage } from "pages/BorrowPage/MobileBorrowPage";
import { DesktopBorrowPage } from "pages/BorrowPage/DesktopBorrowPage";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

const BorrowPage: React.FC = () => {
  const breakpoint = useBreakpoint();

  if (breakpoint === Breakpoint.MOBILE) {
    return <MobileBorrowPage />;
  }

  return <DesktopBorrowPage />;
};

export default BorrowPage;
