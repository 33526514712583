import Web3 from "web3";
import React from "react";
import { useAsyncState } from "hooks/useAsyncState";
import WrappedAbi from "abis/WrappedMToken.json";
import AggregatorAbi from "abis/Aggregator.json";
import { AbiItem, toBN } from "web3-utils";
import { Aggregator } from "generated/Aggregator";

export const useAvalancheTVL = () => {
  const web3 = React.useMemo(
    () => new Web3("https://api.avax.network/ext/bc/C/rpc"),
    []
  );

  const call = React.useCallback(async () => {
    let tvl = toBN(0);

    const wavaWAVAX = new web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0x71003CE2353C91E05293444A9C3225997CcD353C"
    );
    const avaxBalance = toBN(
      await wavaWAVAX.methods
        .underlyingBalanceOf("0xbf03e0f7D8dFB17e4680C4D4748A614968aD5495")
        .call()
    );
    const avaxUsdOracle = (new web3.eth.Contract(
      AggregatorAbi as AbiItem[],
      "0x0A77230d17318075983913bC2145DB16C7366156"
    ) as unknown) as Aggregator;
    const avaxPriceUsd = toBN(
      (await avaxUsdOracle.methods.latestRoundData().call()).answer
    );
    tvl = tvl.add(avaxBalance.mul(avaxPriceUsd).div(toBN(1e8)));

    return tvl;
  }, [web3.eth]);

  return useAsyncState(null, call);
};
