import React from "react";
import { Grid, Heading } from "@theme-ui/components";
import { useHiddenBalances } from "hooks/useHiddenBalances";
import { Page } from "state/global";
import { WalletSummary } from "components/WalletSummary";
import { HomeButton } from "components/HomeButton";
import { Assets } from "components/Assets";
import { TransactionsHistory } from "components/TransactionsHistory";
import {
  Withdrawal as SendIcon,
  Loans as LoansIcon,
  Request as RequestIcon,
} from "assets/icons/index";
import { useTVL } from "hooks/tvl/useTVL";

const HomePage: React.FC = () => {
  const [hiddenBalances] = useHiddenBalances();
  const tvl = useTVL();

  return (
    <>
      <Heading
        as="h1"
        mb={3}
        sx={{ textAlign: "left", display: ["none", "block", "block"] }}
        variant="pageHeader1"
      >
        Your wallet {tvl?.toString()}
      </Heading>

      <Grid columns={[]} sx={{ mb: 3 }}>
        <WalletSummary assets={hiddenBalances} />
      </Grid>

      <Grid
        columns={["1fr 1fr 1fr"]}
        sx={{ mb: 3, display: ["none", "none", "grid"] }}
      >
        <HomeButton
          to={Page.SEND}
          label={"Send"}
          description={`Send crypto privately to any Celo user`}
          icon={<SendIcon size={28} />}
        />
        <HomeButton
          to={Page.REQUEST}
          label={"Request payment"}
          description={"Generate a private crypto request link"}
          icon={<RequestIcon size={28} />}
        />
        <HomeButton
          to={Page.BORROW}
          label={"Borrow"}
          description={"Use your crypto holdings as collateral "}
          icon={<LoansIcon size={28} />}
        />
      </Grid>

      <Grid
        columns={["1fr", "1fr", "2fr 0.98fr"] /*TODO: this 0.98fr is weird*/}
        sx={{ mb: 3 }}
      >
        <Assets assets={hiddenBalances} />
        <TransactionsHistory />
      </Grid>
    </>
  );
};

export default HomePage;
