import React from "react";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { Box, Donut, Flex, Text } from "theme-ui";

export const PoofKitLoading: React.FC = () => {
  const { progress } = PoofKitGlobal.useContainer();

  if (progress >= 1) {
    return null;
  }
  return (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "100%",
        height: "100%",
        background: "#000000aa",
      }}
    >
      <Flex
        sx={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        <Donut min={0} max={1} value={progress} mb={3} />
        <Text sx={{ display: "block", color: "white" }}>
          Downloading zkSNARK proof dependencies...
        </Text>
      </Flex>
    </Box>
  );
};
