import { SUPPORTED_NETWORKS } from "config";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "state";
import { setNetwork as setNetworkAction } from "state/user";
import Web3 from "web3";

export const useWeb3 = () => {
  const network =
    useSelector((state: AppState) => state.user.network) ||
    SUPPORTED_NETWORKS[0];
  const dispatch = useDispatch();
  const setNetwork = React.useCallback(
    (network) => {
      dispatch(setNetworkAction({ network }));
    },
    [dispatch]
  );
  const web3 = React.useMemo(() => new Web3(network.rpc), [network.rpc]);
  return { network, setNetwork, web3 };
};
