import { useCallback, useState } from "react";
import { deployments, unpackEncryptedMessage } from "@poofcash/poof-v2-kit";
import { Account } from "@poofcash/poof-v2-kit/dist/account";
import { AbiItem } from "web3-utils";
import { PoofAccountGlobal } from "./poofAccount";
import { PoofKitGlobal } from "./usePoofKit";
import { useWeb3 } from "./useWeb3";
import PoofArtifact from "abis/Poof.json";
import { Poof } from "generated/Poof";
import { WalletGlobal } from "./useWallet";
import { humanFriendlyWei } from "utils/eth";
import moment from "moment";

export type Transaction = {
  address: string;
  date: string;
  type: string;
  amount: number;
  currency: string;
  blockHash: string;
  blockNumber: number;
  transactionHash: string;
  timestamp: string | number;
};

export const useTransactions = () => {
  const [txns, setTxns] = useState<Transaction[] | null>(null);
  const { network } = useWeb3();
  const { privateKey } = PoofAccountGlobal.useContainer();
  const { poofKit } = PoofKitGlobal.useContainer();
  const { getConnectedWeb3 } = WalletGlobal.useContainer();

  const call = useCallback(async () => {
    if (!privateKey) {
      return null;
    }

    let result: Transaction[] = [];
    try {
      const pools = deployments[network.chainId];
      const unitPerUnderlyings = await Promise.all(
        pools.map((pool) =>
          poofKit.unitPerUnderlying(pool.symbol.toLowerCase())
        )
      );

      await Promise.all(
        pools.map(async (pool, idx) => {
          const web3 = await getConnectedWeb3();
          const poof = (new web3.eth.Contract(
            PoofArtifact as AbiItem[],
            pool.poolAddress
          ) as unknown) as Poof;
          const evs = await poof.getPastEvents("NewAccount", {
            fromBlock: 0,
            toBlock: "latest",
          });
          evs.forEach((e) => {
            try {
              const a = Account.decrypt(
                privateKey,
                unpackEncryptedMessage(e.returnValues.encryptedAccount)
              );

              result.push({
                currency: pool.symbol,
                transactionHash: e.transactionHash,
                address: e.address,
                type: e.event,
                amount: parseFloat(
                  humanFriendlyWei(
                    a.amount.div(unitPerUnderlyings[idx]).toString()
                  )
                ),
                blockHash: e.blockHash,
                blockNumber: e.blockNumber,
                date: "",
                timestamp: 0,
              });
            } catch (e) {
              //skip - could not decrypt account means - it's not ours
            }
          });
          //XXX: await getBlock takes time and is async and because of that - affects sorting that happens later!
          await Promise.all(
            result.map(async (e, idx) => {
              const block = await web3.eth.getBlock(e.blockNumber);
              result[idx].timestamp = (
                parseInt(block.timestamp.toString()) * 1000
              ).toString();
              result[idx].date = moment(
                parseInt(block.timestamp.toString()) * 1000
              ).format();
            })
          );
        })
      );
    } catch (e) {
      console.log("Error fetching transactions: ", e);
    }

    return result;
  }, [privateKey, network.chainId, poofKit, getConnectedWeb3]);

  if (txns !== null) {
    return txns;
  }

  call().then((r) => {
    if (r !== null) {
      //Sort ascending by block number
      const result: Transaction[] = r.sort((a, b) =>
        a.blockNumber < b.blockNumber ? -1 : 1
      );

      //Calculate balance differences (a.k.a. transactions)
      let lastBalance: { [key: string]: number } = {};
      const result2 = result.map((t) => {
        if (lastBalance[t.currency] === undefined) {
          lastBalance[t.currency] = 0;
        }

        const newAmount = t.amount - lastBalance[t.currency];
        lastBalance[t.currency] = t.amount;

        return { ...t, amount: newAmount };
      });

      //sort again, descending this time
      setTxns(result2.sort((a, b) => (a.blockNumber > b.blockNumber ? -1 : 1)));
    }
  });

  return txns;
};
