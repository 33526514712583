import { useAaveApr } from "./useAaveApr";

export const useGeistApr = (currency: string) => {
  const ftmApr = useAaveApr(
    "0x9FAD24f572045c7869117160A571B2e50b10d068",
    "0x21be370d5312f44cb42ce377bc9b8a0cef1a4c83"
  );
  if (currency === "FTM") {
    return ftmApr;
  }
  return [null, () => {}];
};
