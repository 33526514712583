import { BlockscoutTxLink } from "components/Links";
import moment from "moment";
import React from "react";
import { Button, Container, Text } from "theme-ui";
import { GrayBox } from "components/GrayBox";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useRecoilState } from "recoil";
import {
  borrowAmount,
  borrowCurrency,
  borrowRecipient,
  borrowTxHash,
} from "../state";
import { useRelayer } from "hooks/useRelayer";

interface IProps {
  onDoneClick: () => void;
}

export const BorrowReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const [amount] = useRecoilState(borrowAmount);
  const [currency] = useRecoilState(borrowCurrency);
  const [txHash] = useRecoilState(borrowTxHash);
  const [recipient] = useRecoilState(borrowRecipient);

  const { relayerFee } = useRelayer(amount, currency);

  const finalBorrowAmount = Number(amount) + Number(relayerFee);

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
        You have successfully borrowed {currency}.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <GrayBox>
        <Text sx={{ mb: 3 }} variant="subtitle">
          Receipt
        </Text>
        <br />
        <SummaryTable
          title="Transaction"
          lineItems={[
            {
              label: "Time completed",
              value: moment().format("h:mm a"),
            },
            {
              label: "Account",
              value: recipient,
            },
            {
              label: "Borrow amount",
              value: `${humanFriendlyNumber(amount)} ${currency}`,
            },
          ]}
          totalItem={{
            label: "Est. Borrow",
            value: `${humanFriendlyNumber(finalBorrowAmount)} ${currency}`,
          }}
        />
      </GrayBox>

      <Button sx={{ mt: 2, px: 6 }} variant="done" onClick={onDoneClick}>
        Done
      </Button>
    </Container>
  );
};
