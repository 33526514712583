import React from "react";
import { Box, Text } from "theme-ui";
import { PickLogin } from "pages/SetupAccount/PickLogin";
import { PoofAccountGlobal } from "hooks/poofAccount";

export const DesktopLogin: React.FC = () => {
  const { poofAccount } = PoofAccountGlobal.useContainer();

  return (
    <Box mt="25%">
      <Text sx={{ display: "block" }} variant="title">
        Log In
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
        {poofAccount ? (
          <>Enter your password to unlock your Poof wallet</>
        ) : (
          <>Enter your Poof private key* to enter your Poof account</>
        )}
      </Text>
      <PickLogin />
      {!poofAccount && (
        <Box mt={4}>
          <Text variant="form">
            *Your key never leaves the browser and stays encrypted at rest. If
            this is your first time here, you are encouraged to create a new
            Poof wallet rather than use a private key from an existing wallet.
          </Text>
        </Box>
      )}
    </Box>
  );
};
