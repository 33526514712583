import { Donut, Flex, Text } from "@theme-ui/components";
import moment from "moment";
import { useEffect, useState } from "react";

interface IProps {
  message?: string;
  estimatedTimeInSeconds: number;
}
export const TimedSpinner: React.FC<IProps> = ({
  message,
  estimatedTimeInSeconds,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(estimatedTimeInSeconds);
  useEffect(() => {
    const interval = setInterval(
      () => setTimeRemaining(Math.max(timeRemaining - 1, 0)),
      1000
    );
    return () => {
      clearInterval(interval);
    };
  }, [timeRemaining, setTimeRemaining]);
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Donut
        min={0}
        max={estimatedTimeInSeconds}
        value={estimatedTimeInSeconds - timeRemaining}
        mr={2}
        size={32}
      />
      <Text>
        {message} About{" "}
        {moment.duration(timeRemaining, "seconds").humanize(false)} remaining...
      </Text>
    </Flex>
  );
};
