import React from "react";
import "i18n/config";
import SendAndRequestPage from "pages/SendAndRequestPage";
import CompleteRequestPage from "pages/CompleteRequestPage";
import { Box, Container, Link, Text } from "theme-ui";
import { Header } from "components/Header";
import { Page } from "state/global";
import { SetupAccount } from "pages/SetupAccount";
import Modal from "react-modal";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { ToastContainer } from "react-toastify";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { NetworkDrawer } from "components/Network/NetworkDrawer";
import { PoofAccountDrawer } from "components/PoofAccount/PoofAccountDrawer";
import { PrivateRoute } from "components/PrivateRoute";
// import BorrowPage from "pages/BorrowPage";
// import RepayPage from "pages/RepayPage";
import HomePage from "pages/HomePage";
import DepositPage from "pages/DepositPage";
import { PrivateRouteWithDep } from "components/PrivateRouteWithDep";
import LoansPage from "./pages/LoansPage";
import { CurrenciesProvider } from "contexts/currencies";
import { useHiddenBalances } from "hooks/useHiddenBalances";

// pass props and State interface to Component class
const App: React.FC = () => {
  const { passwordModal } = PoofAccountGlobal.useContainer();
  React.useEffect(() => {
    Modal.setAppElement("body");
  });
  const location = useLocation();
  const [hiddenBalances] = useHiddenBalances();

  return (
    <CurrenciesProvider
      pools={hiddenBalances?.map((hb) => ({ symbol: hb.symbol }))}
    >
      <>
        <Container sx={{ textAlign: "center", backgroundColor: "box" }}>
          <Text variant="form">
            Looking for previous versions of Poof?{" "}
            <Link
              href="http://v1.poof.cash"
              target="_blank"
              rel="noopener noreferrer"
            >
              v1
            </Link>
            {", "}
            <Link
              href="http://v2.poof.cash"
              target="_blank"
              rel="noopener noreferrer"
            >
              v2
            </Link>
          </Text>
        </Container>
        <Container
          sx={{
            mx: [0, "5%"],
            my: [0, 4],
            maxWidth: "100%",
            width: "auto",
          }}
        >
          <Box
            sx={{
              display: location.pathname.includes(Page.SETUP)
                ? "none"
                : "inherit",
            }}
          >
            <Header />
          </Box>
          <Container
            sx={{
              px: [3, 0],
              py: location.pathname.includes(Page.SETUP) ? [0, 0] : [4, "5%"],
              mb: "64px",
              maxHeight: "calc(100vh + 64px)",
            }}
          >
            <Switch>
              <Route exact path="/">
                <Redirect to={`/${Page.HOME}`} />
              </Route>
              <PrivateRoute exact path={`/${Page.HOME}`}>
                <HomePage />
              </PrivateRoute>
              <PrivateRouteWithDep exact path={`/${Page.REQUEST}`}>
                <SendAndRequestPage />
              </PrivateRouteWithDep>
              <Route path={`/${Page.COMPLETE_REQUEST}`}>
                <CompleteRequestPage />
              </Route>
              <PrivateRouteWithDep exact path={`/${Page.DEPOSIT}`}>
                <DepositPage />
              </PrivateRouteWithDep>
              <PrivateRouteWithDep exact path={`/${Page.SEND}`}>
                <SendAndRequestPage />
              </PrivateRouteWithDep>
              <PrivateRouteWithDep path={`/${Page.LOANS}`}>
                <LoansPage />
              </PrivateRouteWithDep>
              <Route path={`/${Page.SETUP}`}>
                <SetupAccount />
              </Route>
            </Switch>
          </Container>
        </Container>
        {passwordModal}
        <ToastContainer
          style={{ background: "var(--theme-ui-colors-background)" }}
          toastClassName="toast-body"
          bodyClassName="toast-body"
        />
        <NetworkDrawer />
        <PoofAccountDrawer />
      </>
    </CurrenciesProvider>
  );
};

export default App;
