import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import { BackButton } from "components/BackButton";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import {
  withdrawAmount,
  withdrawCurrency,
  withdrawLoading,
  withdrawTxHash,
} from "../state";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { useWithdraw } from "hooks/actions/useWithdraw";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";

interface IProps {
  onBackClick: () => void;
  onConfirmClick: () => void;
}

export const PRECISION = 7;

export const ConfirmWithdraw: React.FC<IProps> = ({
  onBackClick,
  onConfirmClick,
}) => {
  const [amount] = useRecoilState(withdrawAmount);
  const [currency] = useRecoilState(withdrawCurrency);
  const [txHash] = useRecoilState(withdrawTxHash);
  const [loading, setLoading] = useRecoilState(withdrawLoading);

  const { relayer, relayerFee } = useRelayer(amount, currency);
  const { actWithPoofAccount } = PoofAccountGlobal.useContainer();
  const withdraw = useWithdraw();

  const finalWithdrawAmount = Number(amount) + Number(relayerFee);

  const handleWithdraw = async () => {
    actWithPoofAccount(
      async (privateKey) => {
        try {
          await withdraw(privateKey);
          onConfirmClick();
          if (!txHash) {
            alert(
              "No response from relayer. Check your account in the explorer or try again"
            );
          }
        } catch (e: any) {
          if (e.response) {
            console.error(e.response.data.error);
          } else {
            console.debug(e);
            alert(e.message);
          }
        } finally {
          setLoading(false);
        }
      },
      () => {}
    );
  };

  return (
    <Container>
      <BackButton onClick={onBackClick} />
      <br />
      <Text sx={{ mb: 1 }} variant="subtitle">
        Transaction Summary
      </Text>
      <br />
      <Text sx={{ mb: 4 }} variant="regularGray">
        Please review the transaction before continuing
      </Text>
      <br />

      <SummaryTable
        title="Summary"
        lineItems={[
          {
            label: "Withdrawal Amount",
            value: `${humanFriendlyNumber(amount)} ${currency}`,
          },
          {
            label: `Relayer Fee (${relayer?.relayerFee}%) + Gas`,
            value: `${
              relayerFee ? humanFriendlyNumber(relayerFee) : "-"
            } ${currency}`,
          },
        ]}
        totalItem={{
          label: "Total",
          value: `${humanFriendlyNumber(finalWithdrawAmount)} ${currency}`,
        }}
      />
      <ActionDrawer>
        {loading ? (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <ProofGenLoading />
          </Flex>
        ) : (
          <Flex sx={{ justifyContent: "space-between" }}>
            <LabelWithBalance
              label="Total"
              amount={finalWithdrawAmount}
              currency={currency}
            />
            <Button onClick={handleWithdraw}>Withdraw</Button>
          </Flex>
        )}
      </ActionDrawer>
    </Container>
  );
};
