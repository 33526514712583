import Tokens from './list.json';
import { default as Logo } from "./logo.jsx";

export { default as Withdrawal } from "./withdrawal.jsx";
export { default as Deposit } from "./deposit.jsx";
export { default as Repay } from "./repay.jsx";
export { default as Request } from "./request.jsx";
export { default as Loans } from "./loans.jsx";
export { default as Cog } from "./cog.jsx";
export { default as X } from "./x.jsx";
export { default as Export } from "./export.jsx";

export const logo = (symbol, size = 24) => {
  const s = symbol.toLowerCase();
  const ls = new Map();
  Tokens.forEach(l => {
    ls.set(l.symbol.toLowerCase(), l.logoURI);
  })

  let l = ls.get(s);
  if (!l) {
    return <Logo size={size} />;
  }

  return (<img
    src={l}
    alt=""
    style={{ width: size + "px", height: size + "px", borderRadius: '50%' }}
  />);
}
