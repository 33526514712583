import React from "react";
import { Container, Grid, Text } from "theme-ui";
import { useTranslation } from "react-i18next";
import { PickBorrow } from "../MobileBorrowPage/PickBorrow";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { LoansIllustration } from "../../../assets/LoansIllustration";
import { Flex } from "@theme-ui/components";

interface IProps {
  onBorrowClick: () => void;
}

export const DoBorrow: React.FC<IProps> = ({ onBorrowClick }) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  return (
    <Grid sx={{ gridTemplateColumns: ["auto", "1.3fr 1fr"], gridGap: 6 }}>
      <Container>
        <Text sx={{ display: "block" }} variant="title">
          {t("borrow.desktop.title")}
        </Text>
        <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
          {t("borrow.desktop.subtitle")}
        </Text>
        <PickBorrow onBorrowClick={onBorrowClick} />
      </Container>
      {breakpoint === Breakpoint.DESKTOP && (
        <Flex sx={{ variant: "flex.centered" }}>
          <LoansIllustration />
        </Flex>
      )}
    </Grid>
  );
};
