import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import moment from "moment";
import { BlockscoutTxLink } from "components/Links";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { shortenAccount } from "utils/account";
import { depositAmount, depositCurrency, depositTxHash } from "../state";
import { useRecoilState } from "recoil";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { NETWORK_COST } from "config";
import { WalletGlobal } from "hooks/useWallet";

interface IProps {
  onDoneClick: () => void;
}

export const DepositReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const { gasCurrency } = WalletGlobal.useContainer();
  const [amount] = useRecoilState(depositAmount);
  const [currency] = useRecoilState(depositCurrency);
  const [txHash] = useRecoilState(depositTxHash);
  const { address } = PoofAccountGlobal.useContainer();

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regular">
        You deposit is complete.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <SummaryTable
        title="Transaction"
        lineItems={[
          {
            label: "Time completed",
            value: moment().format("h:mm a"),
          },
          {
            label: "Account",
            value: shortenAccount(address),
          },
          {
            label: "Est. total amount",
            value:
              currency === gasCurrency
                ? `${humanFriendlyNumber(
                    Number(amount) + Number(NETWORK_COST)
                  )} ${gasCurrency}`
                : `${humanFriendlyNumber(
                    amount
                  )} ${currency} + ${humanFriendlyNumber(
                    NETWORK_COST
                  )} ${gasCurrency}`,
          },
        ]}
        totalItem={{
          label: "Deposited",
          value: `${humanFriendlyNumber(amount)} ${currency}`,
        }}
      />

      <ActionDrawer>
        <Flex sx={{ justifyContent: "space-between" }}>
          <LabelWithBalance
            label="Deposited"
            amount={amount}
            currency={currency}
          />
          <Button
            onClick={() => {
              onDoneClick();
            }}
            variant="done"
          >
            Done
          </Button>
        </Flex>
      </ActionDrawer>
    </Container>
  );
};
