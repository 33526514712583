import React from "react";
import Web3 from "web3";
import NomArtifact from "abis/Nom.json";
import { AbiItem } from "web3-utils";
import { NOM } from "config";
import { Nom } from "generated/Nom";

export const useNom = () => {
  return React.useMemo(() => {
    const web3 = new Web3("https://forno.celo.org");
    return (new web3.eth.Contract(
      NomArtifact.abi as AbiItem[],
      NOM
    ) as unknown) as Nom;
  }, []);
};
