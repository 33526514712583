import { useEffect, useState } from "react";
import { humanFriendlyWei } from "../utils/eth";
import { humanFriendlyNumber, parseNumber, toBN } from "../utils/number";
import { logo } from "assets/icons";

export interface AssetSummary {
  totalBalanceInUsd: string;
  totalLoansInUsd: string;
  availableAssetsInUsd: string;
}

export interface AssetsWithSummary {
  summary: AssetSummary;
  assets: any[];
}

export const useAssets = (toUSD: any, assets: any, currencyData: any = {}) => {
  const [transformedAssets, setTransformedAssets] = useState<AssetsWithSummary>(
    {
      assets: [],
      summary: {
        totalBalanceInUsd: "0",
        totalLoansInUsd: "0",
        availableAssetsInUsd: "0",
      },
    }
  );

  useEffect(() => {
    return () => {
      let transformedAssets = { ...currencyData };
      let total_usd = 0.0;
      let assetsWithSummary: AssetsWithSummary;
      if (assets) {
        assets.forEach((a: any) => {
          if (transformedAssets[a.symbol] === undefined) {
            transformedAssets[a.symbol] = {
              icon: logo(a.symbol, 40),
              asset_pcnt: 0,
              credit: 0,
              credit_usd: 0,
            };
          }

          transformedAssets[a.symbol] = {
            ...transformedAssets[a.symbol], //DEFAULTS
            ...a, //DATA from wallets
            amount_formatted: humanFriendlyWei(a.amount),
            amount_usd: humanFriendlyNumber(
              toUSD(humanFriendlyWei(a.amount), a.symbol)
            ),
            amount_debt: humanFriendlyNumber(humanFriendlyWei(a.debt)),
            amount_debt_usd: humanFriendlyNumber(
              toUSD(humanFriendlyWei(a.debt), a.symbol)
            ),
            credit_available: humanFriendlyWei(
              toBN(a.amount).sub(toBN(a.debt))
            ),
          };
          total_usd += parseFloat(transformedAssets[a.symbol].amount_usd);
        });
        Object.entries(transformedAssets).forEach((v: any) => {
          let [key, value] = v;
          transformedAssets[key] = {
            ...value,
            asset_pcnt:
              total_usd <= 0
                ? 0
                : ((parseFloat(value.amount_usd) / total_usd) * 100).toFixed(2),
          };
        });
        transformedAssets = Object.entries(transformedAssets).map((v: any) => {
          let value = v[1];
          return value;
        });

        let totalBalanceInUsd = 0;
        let totalLoansInUsd = 0;
        if (transformedAssets.length > 0) {
          totalBalanceInUsd = transformedAssets
            .map((asset: any) => parseNumber(asset.amount_usd))
            .reduce((val1: any, val2: any) => {
              return val1 + val2;
            });
          totalLoansInUsd = transformedAssets
            .map((asset: any) =>
              parseFloat(
                humanFriendlyNumber(
                  toUSD(humanFriendlyWei(asset.debt), asset.symbol)
                )
              )
            )
            .reduce((val1: any, val2: any) => {
              return val1 + val2;
            });
        }

        assetsWithSummary = {
          assets: transformedAssets,
          summary: {
            totalBalanceInUsd: humanFriendlyNumber(totalBalanceInUsd),
            totalLoansInUsd: humanFriendlyNumber(totalLoansInUsd),
            availableAssetsInUsd: humanFriendlyNumber(
              totalBalanceInUsd - totalLoansInUsd
            ),
          },
        };

        setTransformedAssets(assetsWithSummary);
      }
    };
  }, [assets]); //eslint-disable-line

  return transformedAssets;
};
