const Request = ({ size }) => (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" widt={size} height={size} viewBox={`0 0 24 24`} >
        <path d="M3 13.8V21" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M3 19.8H16.2C16.2 19.1635 15.9471 18.553 15.4971 18.1029C15.047 17.6529 14.4365 17.4 13.8 17.4H10.8C10.8 16.7635 10.5471 16.153 10.0971 15.7029C9.64697 15.2529 9.03652 15 8.4 15H3" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M7.80078 17.4H10.8008" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M13.8008 6.6C13.8008 7.07276 13.8939 7.54089 14.0748 7.97766C14.2557 8.41443 14.5209 8.81129 14.8552 9.14558C15.1895 9.47988 15.5863 9.74505 16.0231 9.92597C16.4599 10.1069 16.928 10.2 17.4008 10.2C17.8735 10.2 18.3417 10.1069 18.7784 9.92597C19.2152 9.74505 19.6121 9.47988 19.9464 9.14558C20.2807 8.81129 20.5458 8.41443 20.7267 7.97766C20.9077 7.54089 21.0008 7.07276 21.0008 6.6C21.0008 6.12724 20.9077 5.65911 20.7267 5.22234C20.5458 4.78557 20.2807 4.38871 19.9464 4.05442C19.6121 3.72012 19.2152 3.45495 18.7784 3.27403C18.3417 3.09312 17.8735 3 17.4008 3C16.928 3 16.4599 3.09312 16.0231 3.27403C15.5863 3.45495 15.1895 3.72012 14.8552 4.05442C14.5209 4.38871 14.2557 4.78557 14.0748 5.22234C13.8939 5.65911 13.8008 6.12724 13.8008 6.6V6.6Z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M17.3996 12.6C17.3997 12.9884 17.3055 13.3709 17.1251 13.7148C16.9448 14.0587 16.6837 14.3538 16.3642 14.5746C16.0448 14.7954 15.6765 14.9353 15.2911 14.9825C14.9056 15.0297 14.5145 14.9826 14.1512 14.8453C13.7879 14.708 13.4634 14.4847 13.2055 14.1944C12.9476 13.9041 12.7639 13.5555 12.6703 13.1786C12.5768 12.8017 12.576 12.4078 12.6682 12.0305C12.7604 11.6533 12.9428 11.3041 13.1996 11.0128" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
);

export default Request;
