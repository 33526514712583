import { toWei } from "web3-utils";

export enum ChainId {
  Mainnet = 42220,
  Alfajores = 44787,
  Baklava = 62320,
}

export type Network = {
  chainId: number;
  name: string;
  rpc: string;
  explorer: string;
  gasCurrency: string;
};

export const SUPPORTED_NETWORKS: Array<Network> = [
  {
    chainId: 42220,
    name: "CELO",
    rpc: "https://forno.celo.org",
    explorer: "https://explorer.celo.org",
    gasCurrency: "CELO",
  },
  {
    chainId: 250,
    name: "Fantom",
    rpc: "https://rpc.ftm.tools",
    explorer: "https://explorer.fantom.network",
    gasCurrency: "FTM",
  },
  {
    chainId: 43114,
    name: "Avalanche",
    rpc: "https://api.avax.network/ext/bc/C/rpc",
    explorer: "https://cchain.explorer.avax.network",
    gasCurrency: "AVAX",
  },
  {
    chainId: 137,
    name: "Polygon",
    rpc: "https://polygon-rpc.com",
    explorer: "https://polygonscan.com",
    gasCurrency: "MATIC",
  },
  {
    chainId: 1,
    name: "Ethereum",
    rpc: process.env.REACT_APP_ETHEREUM_RPC || "",
    explorer: "https://etherscan.io",
    gasCurrency: "ETH",
  },
  ...(process.env.NODE_ENV === "development"
    ? [
        {
          chainId: 44787,
          name: "CELO ALFAJORES",
          rpc: "https://alfajores-forno.celo-testnet.org",
          explorer: "https://alfajores-blockscout.celo-testnet.org",
          gasCurrency: "CELO",
        },
        {
          chainId: 4002,
          name: "Fantom Testnet",
          rpc: "https://rpc.testnet.fantom.network",
          explorer: "https://explorer.testnet.fantom.network",
          gasCurrency: "FTM",
        },
        {
          chainId: 43113,
          name: "Fuji",
          rpc: "https://api.avax-test.network/ext/bc/C/rpc",
          explorer: "https://explorer.avax-test.network",
          gasCurrency: "AVAX",
        },
        {
          chainId: 80001,
          name: "Mumbai",
          rpc: "https://matic-testnet-archive-rpc.bwarelabs.com",
          explorer: "https://mumbai.polygonscan.com",
          gasCurrency: "MATIC",
        },
        {
          chainId: 42,
          name: "Kovan",
          rpc: process.env.REACT_APP_KOVAN_RPC || "",
          explorer: "https://kovan.etherscan.io",
          gasCurrency: "ETH",
        },
      ]
    : []),
];

export const RELAYERS: Record<number, Array<string>> = {
  // Celo
  42220: [
    "https://adamaris.mooo.com",
    "https://bellagio.mooo.com",
    "https://maralago.mooo.com",
    "https://aarde.thkpool.com",
    "https://privatecelo.com",
    "https://poof.vercel.app",
  ],
  44787: [],

  // Fantom
  250: ["https://oyster.now.im"],
  4002: [],

  // Avalanche
  43114: [],
  43113: [],

  // Matic
  80001: [],
  137: ["https://poofmatic.mooo.com"],

  // Ethereum
  1: [],
  42: [],
};

export const DECIMAL_PRECISION = 2; // Number of decimals to show

export const localForageVersion = 1;

export const DEFAULT_GAS_PRICE: Record<number, string> = {
  42220: toWei("0.5", "gwei"),
  44787: toWei("0.5", "gwei"),
  250: toWei("150", "gwei"),
  4002: toWei("100", "gwei"),
};

export const NOM = "0xABf8faBbC071F320F222A526A2e1fBE26429344d";

export const NETWORK_COST = 0.0001;

export const ESTIMATED_PROOF_TIME = 150;
