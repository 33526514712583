import { useCallback } from "react";
import { useWeb3 } from "hooks/useWeb3";
import DataProvider from "abis/MoolaProtocolDataProvider.json";
import { AbiItem, toBN } from "web3-utils";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { MoolaProtocolDataProvider } from "generated/MoolaProtocolDataProvider";
import { useAsyncState } from "hooks/useAsyncState";

export const useMoolaApr = (currency: string) => {
  const { web3, network } = useWeb3();
  const { poofKit } = PoofKitGlobal.useContainer();

  const call = useCallback(async () => {
    if ([42220, 44787].includes(network.chainId)) {
      let dataProvider: MoolaProtocolDataProvider | null = null;
      if (network.chainId === 44787) {
        dataProvider = (new web3.eth.Contract(
          DataProvider as AbiItem[],
          "0x31ccB9dC068058672D96E92BAf96B1607855822E"
        ) as unknown) as MoolaProtocolDataProvider;
      } else if (network.chainId === 42220) {
        dataProvider = (new web3.eth.Contract(
          DataProvider as AbiItem[],
          "0x43d067ed784D9DD2ffEda73775e2CC4c560103A1"
        ) as unknown) as MoolaProtocolDataProvider;
      }

      const poolMatch = await poofKit.poolMatch(currency);
      if (dataProvider && poolMatch?.tokenAddress) {
        const reserveData = await dataProvider.methods
          .getReserveData(poolMatch.tokenAddress)
          .call();
        return (
          Number(
            toBN(reserveData.liquidityRate)
              .div(toBN("100000000000000000000"))
              .toString()
          ) / 100000
        );
      }
    }
    return null;
  }, [currency, network.chainId, poofKit, web3.eth.Contract]);
  return useAsyncState(null, call);
};
