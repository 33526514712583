import React from "react";
import { Box, Card, Flex, Text } from "@theme-ui/components";
import styled from "@emotion/styled/macro";
import { Link } from "react-router-dom";

const HomeCard = styled(Card)({
  boxSizing: "border-box",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
  transition: "background 300ms ease-out",
  [`&:hover`]: {
    background: "#F5F2FF",
  },
});

const Icon = styled(Box)({
  color: "#7C71FC",
  fontSize: "32px",
  width: "48px",
  height: "48px",
  padding: "0px",
  background: "#F5F2FF",
  borderRadius: "106px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  transition: "background 300ms ease-out",
  [`${HomeCard}:hover &`]: {
    background: "#fff",
  },
});

const NoStyleLink = styled(Link)({
  textDecoration: "none",
  color: "var(--theme-ui-colors-primaryButtonColor)",
});

export const Desktop: React.FC<{
  to: string;
  label: string;
  description?: string;
  icon?: any;
}> = ({ to, label, description, icon }) => {
  return (
    <NoStyleLink to={to}>
      <HomeCard
        sx={{
          cursor: "pointer",
          border: ["none", "none", "1px solid #ECE6FE"],
          padding: ["0", "0", "26px 24px"],
        }}
      >
        <Flex
          sx={{
            justifyContent: "flex-start",
            alignItems: "center",
            flexDirection: ["column", "column", "row"],
          }}
        >
          {icon && (
            <Icon sx={{ margin: ["0", "0", "0px 20px 0px 5px"] }}>{icon}</Icon>
          )}
          <div>
            <Text
              variant="form"
              sx={{
                display: "block",
                fontSize: [14, 14, 20],
                textAlign: ["center", "left", "left"],
                fontWeight: [400],
                lineHeight: "28px",
                color: "black",
              }}
              mb={1}
            >
              {label}
            </Text>
            {description && (
              <Text
                sx={{
                  display: "block",
                  fontSize: [16],
                  fontWeight: [400],
                  lineHeight: "20px",
                  color: "gray",
                }}
              >
                {description}
              </Text>
            )}
          </div>
        </Flex>
      </HomeCard>
    </NoStyleLink>
  );
};
