import React from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Input,
  Link,
  Spinner,
  Text,
  Grid,
} from "theme-ui";
import Modal from "components/Modal";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { StyledSelect } from "components/StyledSelect";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { useDebounce } from "hooks/useDebounce";
import { isValidHttpUrl } from "utils/url.utils";
import axios from "axios";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import {
  withdrawAmount,
  withdrawCurrency,
  withdrawLoading,
  withdrawRecipient,
  withdrawResolvedRecipient,
  withdrawTxHash,
} from "../state";
import { deployments } from "@poofcash/poof-v2-kit";
import { fromWei, isAddress, toBN, toWei } from "web3-utils";
import { useHiddenBalances } from "hooks/useHiddenBalances";
import { useWithdraw } from "hooks/actions/useWithdraw";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { isNom } from "utils/nom";
import { useNom } from "hooks/useNom";
import { ethers } from "ethers";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { WalletGlobal } from "hooks/useWallet";
import { X as Close } from "assets/icons/index";

interface IProps {
  settingsOpened: boolean;
  setSettingsOpened: (opened: boolean) => void;
  onWithdrawClick: () => void;
}

export const PickWithdraw: React.FC<IProps> = ({
  onWithdrawClick,
  settingsOpened,
  setSettingsOpened,
}) => {
  const { network } = WalletGlobal.useContainer();
  const [amount, setAmount] = useRecoilState(withdrawAmount);
  const [currency, setCurrency] = useRecoilState(withdrawCurrency);
  React.useEffect(() => {
    setCurrency(
      deployments[network.chainId].find((d) => d.symbol === "cUSD")?.symbol ||
        deployments[network.chainId][0].symbol
    );
  }, [network.chainId, setCurrency]);
  const [recipient, setRecipient] = useRecoilState(withdrawRecipient);
  const [resolvedRecipient, setResolvedRecipient] = useRecoilState(
    withdrawResolvedRecipient
  );
  const [, setTxHash] = useRecoilState(withdrawTxHash);
  const [loading] = useRecoilState(withdrawLoading);
  const { actWithPoofAccount } = PoofAccountGlobal.useContainer();
  const [hiddenBalances] = useHiddenBalances(currency);
  const withdraw = useWithdraw();

  const {
    relayer,
    setSelectedRelayer,
    relayerOptions,
    usingCustomRelayer,
    setUsingCustomRelayer,
    customRelayer,
    setCustomRelayer,
    relayerFee,
  } = useRelayer(
    hiddenBalances?.[0] ? fromWei(hiddenBalances[0].amount.toString()) : null,
    currency
  );
  const breakpoint = useBreakpoint();
  const [customRelayerError, setCustomRelayerError] = React.useState<
    string | null
  >("Required");

  const nom = useNom();
  React.useEffect(() => {
    if (isNom(recipient)) {
      nom.methods
        .resolve(
          ethers.utils.formatBytes32String(recipient.replace(".nom", ""))
        )
        .call()
        .then((r) => {
          setResolvedRecipient(r);
        })
        .catch(console.error);
    } else if (isAddress(recipient)) {
      setResolvedRecipient(recipient);
    } else {
      setResolvedRecipient(null);
    }
  }, [recipient, nom, setResolvedRecipient]);

  const [customRelayerUrl, setCustomRelayerUrl] = React.useState("");
  const onCustomRelayerUrlChange = useDebounce((relayerUrl) => {
    if (isValidHttpUrl(relayerUrl)) {
      axios
        .get(relayerUrl + "/status")
        .then(({ data }) =>
          setCustomRelayer({
            url: relayerUrl,
            relayerFee: data.poofServiceFee,
            miningServiceFee: data.miningServiceFee,
            gasPrices: data.gasPrices,
            celoPrices: data.celoPrices,
          })
        )
        .catch((err) =>
          setCustomRelayerError(
            `${err.message}. Make sure you remove the "/" at the end of your url`
          )
        );
      setCustomRelayerError(null);
    } else if (relayerUrl !== "") {
      setCustomRelayerError("Invalid custom relayer url format");
    }
  }, 200);

  const finalWithdrawAmount = Number(amount) + Number(relayerFee);
  let maxWithdraw = "0";
  if (hiddenBalances?.[0] && relayerFee) {
    maxWithdraw = fromWei(
      hiddenBalances[0].amount
        .sub(toBN(hiddenBalances[0].debt.toString()))
        .sub(toBN(toWei(relayerFee)))
        .toString()
    );
  }

  const withdrawButton = (
    <Button
      variant="submit"
      onClick={() => {
        if (!resolvedRecipient) {
          alert("Recipient address is not valid");
          return;
        }
        actWithPoofAccount(
          async (privateKey) => {
            try {
              await withdraw(privateKey).then((txHash) => {
                if (txHash) {
                  setTxHash(txHash);
                  onWithdrawClick();
                } else {
                  alert(
                    "No response from relayer. It's possible that the transaction still succeeded. Check your balance in 5 minutes before retrying the transaction."
                  );
                }
              });
            } catch (e: any) {
              if (e.response) {
                console.error(e.response.data.error);
              } else {
                console.debug(e);
                alert(e.message);
              }
            }
          },
          () => {}
        );
      }}
      disabled={
        !resolvedRecipient ||
        (usingCustomRelayer && !customRelayer) ||
        isNaN(Number(amount)) ||
        Number(amount) > Number(maxWithdraw)
      }
    >
      Send
    </Button>
  );

  const currencyOptions = deployments[network.chainId].map((entry, idx) => ({
    value: entry.symbol,
    label: entry.symbol,
  }));

  const relayerSelectOptions = [
    ...relayerOptions.map((v, idx) => ({
      label: v.url + " - " + v.relayerFee + "%",
      value: v.url,
    })),
    { value: "custom", label: "Custom" },
  ];

  return (
    <div>
      <Modal modalClosed={() => setSettingsOpened(false)} show={settingsOpened}>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text sx={{ fontWeight: [400], fontSize: [18], lineHeight: "20px" }}>
            Transaction settings
          </Text>
          <Button
            variant="ghost"
            onClick={() => setSettingsOpened(false)}
            sx={{
              margin: 0,
              padding: 0,
              height: "24px !important",
              color: "text",
            }}
          >
            <Close size={24} />
          </Button>
        </Flex>

        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Text variant="form" sx={{ mt: 4, mb: 2 }}>
            Relayer
          </Text>
          <StyledSelect
            value={relayer ? relayer.url : "custom"}
            options={relayerSelectOptions}
            onSelect={(o) => {
              if (o.value === "custom") {
                setCustomRelayer(undefined);
                setUsingCustomRelayer(true);
              } else {
                setSelectedRelayer(
                  relayerOptions?.find(
                    (relayerOption) => relayerOption.url === o.value
                  )
                );
                setUsingCustomRelayer(false);
              }
            }}
          ></StyledSelect>

          {usingCustomRelayer && (
            <>
              <Text variant="form" sx={{ mt: 4, mb: 2 }}>
                Custom relayer url
              </Text>
              <Input
                disabled={loading}
                name="customRelayer"
                type="text"
                value={customRelayerUrl}
                onChange={(event) => {
                  const relayerUrl = event.target.value;
                  setCustomRelayerUrl(relayerUrl);
                  onCustomRelayerUrlChange(relayerUrl);
                }}
                placeholder="Enter custom relayer address (protocol://...)"
              />
              <Text sx={{ mt: 2, color: "red" }} variant="form">
                {customRelayerError}
              </Text>
            </>
          )}
          <Button
            disabled={usingCustomRelayer && customRelayerError !== null}
            onClick={() => setSettingsOpened(false)}
            variant="submit"
          >
            Save
          </Button>
        </Flex>
      </Modal>

      <Text variant="form" sx={{ display: "block", mb: 1 }}>
        Recipient address
      </Text>
      <Input
        mb={2}
        disabled={loading}
        name="recipientAddress"
        type="text"
        onChange={(e) => setRecipient(e.target.value)}
        value={recipient}
        placeholder="Enter wallet address or .nom"
      />
      {recipient !== "" && !resolvedRecipient && (
        <>
          <Text sx={{ mt: 2, color: "red" }} variant="form">
            Invalid address, please try again.
          </Text>
          <br />
        </>
      )}

      <Grid columns={"1fr 3fr"}>
        <Flex sx={{ flexDirection: "column" }}>
          <Text variant="form" sx={{ mb: 1 }}>
            Currency
          </Text>
          <StyledSelect
            value={currency}
            options={currencyOptions}
            onSelect={(e) => setCurrency(e.value)}
          />
        </Flex>
        <div>
          <Flex sx={{ width: "100%", justifyContent: "space-between" }} mb={1}>
            <Text variant="form">Amount</Text>
            <Text sx={{ whiteSpace: "nowrap" }} variant="form">
              <Link
                sx={{ maxWidth: "100%" }}
                onClick={() => {
                  if (hiddenBalances?.[0]) {
                    setAmount(maxWithdraw);
                  }
                }}
              >
                max: {humanFriendlyNumber(maxWithdraw)} {currency}
              </Link>
            </Text>
          </Flex>
          <Input
            sx={{ width: "100%" }}
            placeholder="Enter an amount to completeRequest"
            onChange={(e) => {
              const input = e.target.value;
              if (!isNaN(Number(input))) {
                setAmount(input);
              }
            }}
            value={amount}
          />
        </div>
      </Grid>

      {breakpoint === Breakpoint.DESKTOP && (
        <Container mt={4}>
          {loading ? <ProofGenLoading /> : withdrawButton}
        </Container>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <Box mt={6}>
            <SummaryTable
              lineItems={[
                {
                  label: "Withdrawal Amount",
                  value: `${humanFriendlyNumber(amount)} ${currency}`,
                },
                {
                  label: `Relayer Fee (${relayer?.relayerFee}%) + Gas`,
                  value: `${
                    relayerFee ? humanFriendlyNumber(relayerFee) : "-"
                  } ${currency}`,
                },
              ]}
              totalItem={{
                label: "Total",
                value: `${humanFriendlyNumber(
                  finalWithdrawAmount
                )} ${currency}`,
              }}
            />
          </Box>
          <ActionDrawer>
            <Flex
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <LabelWithBalance
                label="Total"
                amount={amount}
                currency={currency}
              />
              {loading ? <Spinner /> : withdrawButton}
            </Flex>
          </ActionDrawer>
        </>
      )}
    </div>
  );
};
