import React from "react";
import { Box, Card, Flex } from "@theme-ui/components";
import { Button, Grid, Text } from "theme-ui";
import { Link } from "react-router-dom";
import { Loans as LoansIcon } from "../../../assets/icons";
import { Repay as RepayIcon } from "../../../assets/icons";

import { Money } from "../../../components/WalletSummary/Desktop";
import { AssetSummary } from "../../../hooks/useAssets";
import { ProgressBar } from "../../../components/ProgressBar";
import { getPercentage } from "../../../utils/number";

const CreditSummary: React.FC<{
  summary: AssetSummary;
}> = ({ summary }) => {
  return (
    <Card variant="bordered">
      <Flex sx={{ justifyContent: "space-between" }}>
        <Flex sx={{ flexDirection: "column" }}>
          <Box sx={{ variant: "box.label" }}>Borrow summary</Box>
          <Money sx={{ fontSize: "36px", lineHeight: "49px" }}>
            ${summary.totalLoansInUsd}
          </Money>
        </Flex>
      </Flex>
      <Grid columns={1}>
        <Box>
          <ProgressBar
            showPercentage={false}
            value={summary.totalLoansInUsd}
            max={summary.availableAssetsInUsd}
          />
        </Box>
        <Grid columns={"1fr 4fr"}>
          <Box>
            {getPercentage(
              summary.totalLoansInUsd,
              summary.availableAssetsInUsd
            )}
          </Box>
          <Flex sx={{ alignItems: "center", justifyContent: "flex-end" }}>
            <Box>Credit available</Box>
            <Box ml={1}>${summary.availableAssetsInUsd}</Box>
          </Flex>
        </Grid>
      </Grid>

      <Flex>
        <Link to={"/loans/borrow"}>
          <Button variant="primary" sx={{ display: "inline-block" }}>
            <Grid
              columns={"auto 1fr"}
              gap={"4px"}
              sx={{ alignItems: "center" }}
            >
              <LoansIcon size={20} />
              Borrow
            </Grid>
          </Button>
        </Link>
        <Link to={"/loans/repay"}>
          <Button variant="primary" sx={{ display: "inline-block" }} ml={1}>
            <Grid
              columns={"auto 1fr"}
              gap={"4px"}
              sx={{ alignItems: "center" }}
            >
              <RepayIcon size={20} />
              Repay
            </Grid>
          </Button>
        </Link>
      </Flex>

      <Flex
        sx={{ flexDirection: "column", borderTop: "1px solid #e5e5e5" }}
        pt={4}
      >
        <Flex sx={{ justifyContent: "space-between" }}>
          <Text>Wallet balance</Text>
          <Money>${summary.totalBalanceInUsd}</Money>
        </Flex>
        <Flex sx={{ justifyContent: "space-between" }}>
          <Text>Credit</Text>
          <Money>-${summary.totalLoansInUsd}</Money>
        </Flex>
      </Flex>
    </Card>
  );
};

export default CreditSummary;
