import { ESTIMATED_PROOF_TIME } from "config";
import React from "react";
import { TimedSpinner } from "./TimedSpinner";

export const ProofGenLoading: React.FC = () => {
  return (
    <TimedSpinner
      estimatedTimeInSeconds={ESTIMATED_PROOF_TIME}
      message="Generating zkSNARK proof."
    />
  );
};
