import React from "react";
import { useRecoilState } from "recoil";
import { completeRequestTxHash } from "pages/CompleteRequestPage/state";
import { PoofKit } from "@poofcash/poof-v2-kit";
import { useRequestParams } from "hooks/useRequestParams";
import { WalletGlobal } from "hooks/useWallet";

export function useCompleteRequest() {
  const { amount, currency, data } = useRequestParams();
  const { getConnectedWeb3, address, gasPrice } = WalletGlobal.useContainer();
  const [, setTxHash] = useRecoilState(completeRequestTxHash);

  // TODO: TreeUpdate always should be used here
  const completeRequest = React.useCallback(async () => {
    if (!amount || !currency || !data) {
      alert("Invalid completeRequest request");
      return;
    }
    const web3 = await getConnectedWeb3();
    const poofKit = new PoofKit(web3);
    const poolMatch = await poofKit.poolMatch(currency);
    if (poolMatch) {
      try {
        const params = {
          from: address || undefined,
          to: poolMatch.poolAddress,
          data,
          gasPrice,
          value: poolMatch.tokenAddress ? 0 : amount,
        };
        const gas = await web3.eth.estimateGas(params);
        const tx = await web3.eth.sendTransaction({
          ...params,
          gas,
        });
        setTxHash(tx.transactionHash);
      } catch (e) {
        throw e;
      }
    }
  }, [address, amount, currency, data, gasPrice, getConnectedWeb3, setTxHash]);

  return completeRequest;
}
