import React from "react";
import { ConfirmRequest } from "pages/RequestPage/MobileRequestPage/ConfirmRequest";
import { RequestReceipt } from "pages/RequestPage/MobileRequestPage/RequestReceipt";
import { DoRequest } from "../DesktopRequestPage/DoRequest";

enum RequestStep {
  PICKER = "PICKER",
  CONFIRM = "CONFIRM",
  RECEIPT = "RECEIPT",
}

// pass props and State interface to Component class
const MobileRequestPage = () => {
  const [requestStep, setRequestStep] = React.useState(RequestStep.PICKER);

  switch (requestStep) {
    case RequestStep.PICKER:
      return (
        <DoRequest
          onRequestClick={() => {
            setRequestStep(RequestStep.RECEIPT);
          }}
        />
      );
    // This path is never displayed
    case RequestStep.CONFIRM:
      return (
        <ConfirmRequest
          onBackClick={() => setRequestStep(RequestStep.PICKER)}
          onConfirmClick={() => {
            setRequestStep(RequestStep.RECEIPT);
          }}
        />
      );
    case RequestStep.RECEIPT:
      return (
        <RequestReceipt
          onDoneClick={() => {
            setRequestStep(RequestStep.PICKER);
          }}
        />
      );
  }
};

export default MobileRequestPage;
