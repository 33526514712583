import React from "react";
import { ConfirmDeposit } from "pages/DepositPage/MobileDepositPage/ConfirmDeposit";
import { DepositReceipt } from "pages/DepositPage/MobileDepositPage/DepositReceipt";
import { DoDeposit } from "../DesktopDepositPage/DoDeposit";

enum DepositStep {
  PICKER = "PICKER",
  CONFIRM = "CONFIRM",
  RECEIPT = "RECEIPT",
}

// pass props and State interface to Component class
const MobileDepositPage = () => {
  const [depositStep, setDepositStep] = React.useState(DepositStep.PICKER);

  switch (depositStep) {
    case DepositStep.PICKER:
      return (
        <DoDeposit
          onDepositClick={() => {
            setDepositStep(DepositStep.RECEIPT);
          }}
        />
      );
    // This path is never displayed
    case DepositStep.CONFIRM:
      return (
        <ConfirmDeposit
          onBackClick={() => setDepositStep(DepositStep.PICKER)}
          onConfirmClick={() => {
            setDepositStep(DepositStep.RECEIPT);
          }}
        />
      );
    case DepositStep.RECEIPT:
      return (
        <DepositReceipt
          onDoneClick={() => {
            setDepositStep(DepositStep.PICKER);
          }}
        />
      );
  }
};

export default MobileDepositPage;
