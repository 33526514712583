import Web3 from "web3";
import React from "react";
import { useAsyncState } from "hooks/useAsyncState";
import WrappedAbi from "abis/WrappedMToken.json";
import AggregatorAbi from "abis/Aggregator.json";
import { AbiItem, toBN } from "web3-utils";
import { Aggregator } from "generated/Aggregator";

export const useFantomTVL = () => {
  const ftmWeb3 = React.useMemo(() => new Web3("https://rpc.ftm.tools"), []);

  const call = React.useCallback(async () => {
    let tvl = toBN(0);

    const wgFTM = new ftmWeb3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0xe74abf23e1fdf7acbec2f3a30a772ef77f1601e1"
    );
    const ftmBalance = toBN(
      await wgFTM.methods
        .underlyingBalanceOf("0xAdfC2a82becC26C48ed0d1A06C813d283cB39006")
        .call()
    );
    const ftmUsdOracle = (new ftmWeb3.eth.Contract(
      AggregatorAbi as AbiItem[],
      "0xf4766552D15AE4d256Ad41B6cf2933482B0680dc"
    ) as unknown) as Aggregator;
    const ftmPriceUsd = toBN(
      (await ftmUsdOracle.methods.latestRoundData().call()).answer
    );
    tvl = tvl.add(ftmBalance.mul(ftmPriceUsd).div(toBN(1e8)));

    return tvl;
  }, [ftmWeb3.eth]);

  return useAsyncState(null, call);
};
