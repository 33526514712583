import React from "react";
import MobileRepayPage from "pages/RepayPage/MobileRepayPage";
import DesktopRepayPage from "pages/RepayPage/DesktopRepayPage";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

const RepayPage: React.FC = () => {
  const breakpoint = useBreakpoint();

  if (breakpoint === Breakpoint.MOBILE) {
    return <MobileRepayPage />;
  }

  return <DesktopRepayPage />;
};

export default RepayPage;
