import axios from "axios";
import { RELAYERS } from "config";
import React from "react";
import { atom, useRecoilState } from "recoil";
import { fromWei, toWei, toBN } from "web3-utils";
import { calculateFee, PoofKit } from "@poofcash/poof-v2-kit";
import { useAsyncState } from "./useAsyncState";
import Web3 from "web3";
import { WalletGlobal } from "./useWallet";

export type RelayerOption = {
  url: string;
  relayerFee: number;
  miningServiceFee: number;
  gasPrices: Record<string, string>;
  celoPrices: Record<string, string>;
};

const selectedRelayerState = atom<RelayerOption | undefined>({
  key: "SELECTED_RELAYER",
  default: undefined,
});
const relayerOptionsState = atom<Array<RelayerOption>>({
  key: "RELAYER_OPTIONS",
  default: [],
});
const customRelayerState = atom<RelayerOption | undefined>({
  key: "CUSTOM_RELAYER",
  default: undefined,
});
const usingCustomState = atom<boolean>({
  key: "USING_CUSTOM",
  default: false,
});

export const useRelayer = (amount: string | null, currency: string) => {
  const [selectedRelayer, setSelectedRelayer] = useRecoilState(
    selectedRelayerState
  );
  const [relayerOptions, setRelayerOptions] = useRecoilState(
    relayerOptionsState
  );
  const [customRelayer, setCustomRelayer] = useRecoilState(customRelayerState);
  const [usingCustomRelayer, setUsingCustomRelayer] = useRecoilState(
    usingCustomState
  );
  const { network, web3, gasPrice } = WalletGlobal.useContainer();

  React.useEffect(() => {
    const fn = async () => {
      const start = Date.now();
      const statuses = (
        await Promise.all(
          RELAYERS[network.chainId].map((relayerUrl: string) => {
            return axios
              .get(relayerUrl + "/v1/status")
              .then((res) => {
                return {
                  ...res,
                  // Compute each relayer's response latency
                  latency: Date.now() - start,
                };
              })
              .catch((e) => e);
          })
        )
      ).filter((result) => {
        if (result instanceof Error) {
          console.error(result);
          return false;
        }
        return true;
      });

      // Sort by relayer latency in ascending order
      statuses.sort((a, b) => a.latency - b.latency);

      const relayerOptions = statuses.map((status: any) => ({
        url: status.config.url.split("/v1/status")[0],
        relayerFee: status.data.poofServiceFee,
        miningServiceFee: status.data.miningServiceFee,
        gasPrices: status.data.gasPrices,
        celoPrices: status.data.celoPrices,
      }));

      setRelayerOptions(relayerOptions);
      if (relayerOptions.length > 0) {
        setSelectedRelayer(relayerOptions[0]);
      } else {
        setUsingCustomRelayer(true);
      }
    };
    fn();
  }, [setRelayerOptions, setSelectedRelayer, network, setUsingCustomRelayer]);

  const relayer = React.useMemo(
    () => (usingCustomRelayer ? customRelayer : selectedRelayer),
    [customRelayer, selectedRelayer, usingCustomRelayer]
  );

  const call = React.useCallback(async () => {
    const poofKit = new PoofKit((web3 as unknown) as Web3);
    const poolMatch = await poofKit.poolMatch(currency);
    if (poolMatch && relayer && amount && currency) {
      return fromWei(
        calculateFee(
          toBN(toWei(amount)),
          Number(relayer.celoPrices[poolMatch.symbol.toLowerCase()]),
          Number(relayer.relayerFee),
          Number(fromWei(gasPrice, "gwei")),
          2e6
        )
          .mul(toBN(1001))
          .div(toBN(1000))
          .toString()
      );
    }
    return "0";
  }, [amount, currency, gasPrice, relayer, web3]);
  const [relayerFee] = useAsyncState(null, call);

  return {
    relayer,
    setSelectedRelayer,
    relayerOptions,
    usingCustomRelayer,
    setUsingCustomRelayer,
    customRelayer,
    setCustomRelayer,
    relayerFee,
  };
};
