import React from "react";
import { Card, Flex, Text } from "@theme-ui/components";
import styled from "@emotion/styled";
import { Export as ExportIcon } from "assets/icons/index";
import { shortenAccount } from "utils/account";
import { humanFriendlyDate } from "utils/date";
import { humanFriendlyNumber } from "utils/number";
import { Transaction } from "hooks/useTransactions";
import { CSVLink } from "react-csv";
import moment from "moment";

const Outline = styled(Card)({
  padding: "26px 24px",
  border: "1px solid #ECE6FE",
  boxSizing: "border-box",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
});

const Header = styled.div({
  fontWeight: 400,
  fontSize: [18],
  lineHeight: "24px",
  color: "#292B30",
  width: "100%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Row = styled(Flex)({
  borderBottom: "1px solid #ECE6FE",
  margin: "0 -24px",
  padding: "22px 24px",
  ":first-child": {
    marginTop: "-26px",
  },
  ":last-child": {
    borderBottom: "none",
  },
});

const ExportButton = styled(CSVLink)({
  display: "flex",
  color: "#5352fc",
  textDecoration: "none",
  fontSize: [16],
  fontWeight: 400,
  lineHeight: "22px",
  fontFamily: "Medium",
});

export const Desktop: React.FC<{
  txns?: Transaction[] | null;
}> = ({ txns = null }) => {
  if (txns === null) {
    return null;
  }

  return (
    <Outline>
      <Row>
        <Header>
          Transactions History{" "}
          {txns && (
            <ExportButton
              data={txns}
              filename={`transactions-history-${moment().format()}.csv`}
            >
              Export <ExportIcon size={20} />
            </ExportButton>
          )}
        </Header>
      </Row>

      {txns &&
        txns.map((a: any) => (
          <Row
            sx={{
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {shortenAccount("0x" + a.transactionHash, 4)}
              </Text>
              <Text
                sx={{
                  fontWeight: [400],
                  fontSize: [14],
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                {humanFriendlyDate(a.date)}
              </Text>
            </Flex>

            <Flex
              sx={{
                alignItems: "flex-end",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {a.amount > 0 ? "+" : a.amount < 0 ? "-" : ""}
                {humanFriendlyNumber(a.amount)} {a.currency}
              </Text>
              <Text
                sx={{
                  fontWeight: [400],
                  fontSize: [14],
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                {a.type}
              </Text>
            </Flex>
          </Row>
        ))}
    </Outline>
  );
};
