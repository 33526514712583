import { createReducer, createAction } from "@reduxjs/toolkit";
import { Network, SUPPORTED_NETWORKS } from "config";
import { EncryptedKeystoreV3Json } from "web3-core";

// Typings
interface UserState {
  readonly poofAccount?: EncryptedKeystoreV3Json;
  readonly network: Network;
}

const initialState: UserState = {
  network: SUPPORTED_NETWORKS[0],
};

// Actions
export const setAccount = createAction<{
  poofAccount?: EncryptedKeystoreV3Json;
}>("user/setAccount");

export const setNetwork = createAction<{
  network: Network;
}>("user/setNetwork");

// Reducer
export default createReducer(initialState, (builder) =>
  builder
    .addCase(setAccount, (state, action) => {
      const { poofAccount } = action.payload;
      return { ...state, poofAccount };
    })
    .addCase(setNetwork, (state, action) => {
      const { network } = action.payload;
      return { ...state, network };
    })
);
