import React from "react";
import { toBN, toWei } from "web3-utils";
import { useRecoilState } from "recoil";
import {
  borrowAmount,
  borrowCurrency,
  borrowLoading,
  borrowResolvedRecipient,
} from "pages/BorrowPage/state";
import { useRelayer } from "hooks/useRelayer";
import { PoofKitGlobal } from "hooks/usePoofKit";

export function useBorrow() {
  const [amount] = useRecoilState(borrowAmount);
  const [currency] = useRecoilState(borrowCurrency);
  const [recipient] = useRecoilState(borrowResolvedRecipient);
  const [, setLoading] = useRecoilState(borrowLoading);
  const { relayer } = useRelayer(amount, currency);
  const { poofKit, progress } = PoofKitGlobal.useContainer();

  const borrow = React.useCallback(
    async (privateKey: string) => {
      if (progress < 1) {
        alert("Poof kit still loading");
        return;
      }
      if (!relayer) {
        alert("Relayer is not defined");
        return;
      }
      if (!recipient) {
        alert("Recipient not resolved");
        return;
      }
      setLoading(true);
      let txHash;
      try {
        txHash = await poofKit.withdraw(
          privateKey,
          currency,
          toBN(0),
          toBN(toWei(amount)),
          recipient,
          relayer.url
        );
        return txHash;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    },
    [poofKit, currency, amount, recipient, relayer, progress, setLoading]
  );

  return borrow;
}
