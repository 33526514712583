import React from "react";
import { getProofDeps, PoofKit } from "@poofcash/poof-v2-kit";
import { createContainer } from "unstated-next";
import { wrap } from "comlink";
import localForage from "localforage";
import { WalletGlobal } from "./useWallet";

const version = 1;
const depositWasmKey = `depositWasm${version}`;
const depositZkeyKey = `depositZkey${version}`;
const withdrawWasmKey = `withdrawWasm${version}`;
const withdrawZkeyKey = `withdrawZkey${version}`;
const inputRootWasmKey = `inputRootWasm${version}`;
const inputRootZkeyKey = `inputRootZkey${version}`;
const outputRootWasmKey = `outputRootWasm${version}`;
const outputRootZkeyKey = `outputRootZkey${version}`;

const usePoofKit = () => {
  const { web3 } = WalletGlobal.useContainer();
  const [progress] = React.useState(1); // TODO: Set progress to 0 as hardcode
  const poofKit = React.useMemo(() => {
    return new PoofKit(web3);
  }, [web3]);
  const snarkJs = React.useMemo(() => {
    const worker = new Worker("snarkjs.min.js");
    return wrap<any>(worker);
  }, []);
  const getDepLambda = React.useCallback((cacheKey, remoteUrl) => {
    return async () => {
      let cached = await localForage.getItem<Uint8Array>(cacheKey);
      if (cached) {
        return cached;
      }
      const [dep] = await getProofDeps([remoteUrl]);
      localForage.setItem(cacheKey, dep);
      return dep;
    };
  }, []);
  // Recursively try to initialize
  React.useEffect(() => {
    const tryInit = async () => {
      poofKit.initialize(() => snarkJs);
      poofKit.initializeDeposit(
        getDepLambda(
          depositWasmKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/Deposit2.wasm.gz"
        ),
        getDepLambda(
          depositZkeyKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/Deposit2_circuit_final.zkey.gz"
        )
      );
      poofKit.initializeWithdraw(
        getDepLambda(
          withdrawWasmKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/Withdraw2.wasm.gz"
        ),
        getDepLambda(
          withdrawZkeyKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/Withdraw2_circuit_final.zkey.gz"
        )
      );
      poofKit.initializeInputRoot(
        getDepLambda(
          inputRootWasmKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/InputRoot.wasm.gz"
        ),
        getDepLambda(
          inputRootZkeyKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/InputRoot_circuit_final.zkey.gz"
        )
      );
      poofKit.initializeOutputRoot(
        getDepLambda(
          outputRootWasmKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/OutputRoot.wasm.gz"
        ),
        getDepLambda(
          outputRootZkeyKey,
          "https://poof.nyc3.cdn.digitaloceanspaces.com/OutputRoot_circuit_final.zkey.gz"
        )
      );
    };
    tryInit();
  }, [getDepLambda, poofKit, snarkJs]);
  return { poofKit, progress };
};

export const PoofKitGlobal = createContainer(usePoofKit);
