import React from "react";
import { Button, Text, Spinner } from "@theme-ui/components";
import { Box, Flex, Input, Link, Select } from "theme-ui";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { deployments } from "@poofcash/poof-v2-kit";
import { useRecoilState } from "recoil";
import {
  depositAmount,
  depositCurrency,
  DepositStage,
  depositStage,
} from "../state";
import { useDeposit } from "hooks/actions/useDeposit";
import { useDepositApprove } from "hooks/actions/useDepositApprove";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useTokenBalance } from "hooks/useTokenBalance";
import { fromWei, toBN, toWei } from "web3-utils";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { WalletGlobal } from "hooks/useWallet";

interface IProps {
  onDepositClick?: () => void;
}

// pass props and State interface to Component class
export const PickDeposit: React.FC<IProps> = ({ onDepositClick }) => {
  const { connect, address, network } = WalletGlobal.useContainer();
  const [connected, setConnected] = React.useState(false);
  const [amount, setAmount] = useRecoilState(depositAmount);
  const [currency, setCurrency] = useRecoilState(depositCurrency);
  React.useEffect(() => {
    setCurrency(
      deployments[network.chainId].find((d) => d.symbol === "cUSD")?.symbol ||
        deployments[network.chainId][0].symbol
    );
  }, [network.chainId, setCurrency]);
  const [stage, setStage] = useRecoilState(depositStage);
  const [balance, refetchBalance] = useTokenBalance(currency, address);

  const maxDeposit = balance
    ? fromWei(balance.ownerBalance.mul(toBN(1000)).div(toBN(1001))).toString()
    : '0"';

  const breakpoint = useBreakpoint();

  const deposit = useDeposit();
  const depositApprove = useDepositApprove();

  const approved = balance.allowance.gt(
    toBN(toWei(isNaN(Number(amount)) ? "0" : Number(amount).toString()))
  );
  let button = (
    <Button
      onClick={async () => {
        await connect()
          .then(() => setConnected(true))
          .catch((e) => {
            console.error(e);
          });
      }}
    >
      Connect Wallet
    </Button>
  );
  if (connected) {
    button = (
      <Button
        onClick={async () => {
          try {
            if (!approved) {
              await depositApprove();
            }
            await deposit();
            refetchBalance();
            onDepositClick && onDepositClick();
          } catch (e: any) {
            console.error(e);
            alert(e.message);
          } finally {
            setStage(DepositStage.START);
          }
        }}
        disabled={
          isNaN(Number(amount)) ||
          Number(amount) === 0 ||
          Number(amount) > Number(maxDeposit)
        }
      >
        Deposit
      </Button>
    );
  }

  return (
    <>
      <Text variant="form" sx={{ mb: 2 }}>
        Currency
      </Text>
      <Select
        mb={4}
        value={currency}
        onChange={(e) => setCurrency(e.target.value)}
      >
        {deployments[network.chainId].map((entry, idx) => {
          return (
            <option value={entry.symbol} key={idx}>
              {entry.symbol}
            </option>
          );
        })}
      </Select>

      <Box mb={4}>
        <Flex sx={{ width: "100%", justifyContent: "space-between" }} mb={1}>
          <Text variant="form">Amount</Text>
          {connected && (
            <Text sx={{ whiteSpace: "nowrap" }} variant="form">
              <Link
                sx={{ maxWidth: "100%" }}
                onClick={() => {
                  if (balance) {
                    setAmount(maxDeposit);
                  }
                }}
              >
                max: {humanFriendlyNumber(maxDeposit)} {currency}
              </Link>
            </Text>
          )}
        </Flex>
        <Input
          sx={{ width: "100%" }}
          placeholder="Enter an amount to deposit"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </Box>

      {breakpoint === Breakpoint.DESKTOP && (
        <Box mt={4}>
          {stage === DepositStage.TXN_SENDING && (
            <Flex sx={{ alignItems: "center" }}>
              <Spinner mr={2} />
              <Text>Sending transaction through the network...</Text>
            </Flex>
          )}
          {stage === DepositStage.PROVING && <ProofGenLoading />}
          {stage === DepositStage.SIGNING && (
            <Text>To continue, sign the transaction in your wallet.</Text>
          )}
          {stage === DepositStage.START && button}
        </Box>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <Box mt={7}>
            <SummaryTable
              lineItems={[
                {
                  label: "Withdrawal Amount",
                  value: `${humanFriendlyNumber(amount)} ${currency}`,
                },
              ]}
              totalItem={{
                label: "Total",
                value: `${humanFriendlyNumber(amount)} ${currency}`,
              }}
            />
          </Box>
          <ActionDrawer>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <LabelWithBalance
                label="Total"
                amount={amount}
                currency={currency}
              />
              {stage === DepositStage.TXN_SENDING && (
                <Flex sx={{ alignItems: "center" }}>
                  <Spinner mr={2} />
                  <Text>Sending transaction through the network...</Text>
                </Flex>
              )}
              {stage === DepositStage.PROVING && <ProofGenLoading />}
              {stage === DepositStage.SIGNING && (
                <Text>To continue, sign the transaction in your wallet.</Text>
              )}
              {stage === DepositStage.START && button}
            </Flex>
          </ActionDrawer>
        </>
      )}
    </>
  );
};
