import React, { useContext, useEffect, useState } from "react";
import { Button, Flex, Text } from "theme-ui";
import { Link, Route, useLocation } from "react-router-dom";
import { Page } from "state/global";
import { SwitchButtons } from "../SendAndRequestPage";
import { Box, Grid } from "@theme-ui/components";
import BorrowPage from "../BorrowPage";
import RepayPage from "../RepayPage";
import LoansSummary from "./LoansSummary";
import { ArrowLeft } from "react-feather";
import { StyledLink } from "../../components/StyledLink";
import { useHiddenBalances } from "../../hooks/useHiddenBalances";
import { AssetsWithSummary, useAssets } from "../../hooks/useAssets";
import { CurrenciesContext } from "contexts/currencies";

const LoansPage: React.FC = () => {
  const [current, setCurrent] = useState(Page.LOANS);
  const location = useLocation();

  const [hiddenBalances] = useHiddenBalances();
  const { toUSD } = useContext(CurrenciesContext);

  const assetsWithSummary: AssetsWithSummary = useAssets(
    toUSD,
    hiddenBalances,
    {}
  );

  useEffect(() => {
    if (location.pathname.includes(Page.BORROW)) {
      setCurrent(Page.BORROW);
    }
    if (location.pathname.includes(Page.REPAY)) {
      setCurrent(Page.REPAY);
    }
    if (location.pathname === "/loans") {
      setCurrent(Page.LOANS);
    }
  }, [location]);

  return (
    <>
      {current !== Page.LOANS && (
        <Grid
          sx={{
            marginBottom: "52px",
            gridTemplateColumns: "200px 1fr 200px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <StyledLink to={"/loans"}>
            <Flex sx={{ alignItems: "center" }}>
              <ArrowLeft />
              <Text
                sx={{
                  color: "purple400",
                  textDecoration: "none",
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                Back to loans
              </Text>
            </Flex>
          </StyledLink>
          <Flex sx={{ justifyContent: "center" }}>
            <SwitchButtons sx={{ display: "inline-block" }} active={current}>
              <Link to={"/loans/borrow"}>
                <Button
                  className={current === Page.BORROW ? "active" : ""}
                  sx={{ display: "inline-block" }}
                >
                  Borrow
                </Button>
              </Link>
              <Link to={"/loans/repay"}>
                <Button
                  className={current === Page.REPAY ? "active" : ""}
                  sx={{ display: "inline-block" }}
                >
                  Repay
                </Button>
              </Link>
            </SwitchButtons>
          </Flex>
          <Box></Box>
        </Grid>
      )}

      <Route
        exact
        path="/loans"
        render={() => <LoansSummary assetsWithSummary={assetsWithSummary} />}
      />
      <Route exact path="/loans/borrow" component={BorrowPage} />
      <Route exact path="/loans/repay" component={RepayPage} />
    </>
  );
};
export default LoansPage;
