import React from "react";
import { useAsyncState } from "hooks/useAsyncState";
import WrappedAbi from "abis/WrappedMToken.json";
import { AbiItem, toBN, toWei } from "web3-utils";
import { WrappedMToken } from "generated/WrappedMToken";
import { StableToken, newKitFromWeb3 } from "@celo/contractkit";
import Web3 from "web3";

export const useCeloTVL = () => {
  const kit = React.useMemo(() => {
    const web3 = new Web3("https://forno.celo.org");
    return newKitFromWeb3(web3 as any);
  }, []);

  const call = React.useCallback(async () => {
    let tvl = toBN(0);

    const wmcUSD = (new kit.web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0x5b46A20284366F5e79D9B3e5e2FA0F5702b8C72F"
    ) as unknown) as WrappedMToken;
    tvl = tvl.add(
      toBN(
        await wmcUSD.methods
          .underlyingBalanceOf("0xB4aa2986622249B1F45eb93F28Cfca2b2606d809")
          .call()
      )
    );

    const wmCELO = (new kit.web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0xd3D7831D502Ab85319E1F0A18109aa9aBEBC2603"
    ) as unknown) as WrappedMToken;
    const exchangeUsd = await kit.contracts.getExchange(StableToken.cUSD);
    const celoPriceUsd = toBN(
      (await exchangeUsd.quoteGoldSell(toWei("1"))).toFixed()
    );
    tvl = tvl.add(
      toBN(
        await wmCELO.methods
          .underlyingBalanceOf("0xE74AbF23E1Fdf7ACbec2F3a30a772eF77f1601E1")
          .call()
      )
        .mul(celoPriceUsd)
        .div(toBN(toWei("1")))
    );

    const wmcEUR = (new kit.web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0xb7e4e9329DA677969376cc76e87938563B07Ac6A"
    ) as unknown) as WrappedMToken;
    const exchangeEur = await kit.contracts.getExchange(StableToken.cEUR);
    const eurPriceUsd = toWei(
      celoPriceUsd.div(
        toBN((await exchangeEur.quoteGoldSell(toWei("1"))).toFixed())
      )
    );
    tvl = tvl.add(
      toBN(
        await wmcEUR.methods
          .underlyingBalanceOf("0x56072D4832642dB29225dA12d6Fd1290E4744682")
          .call()
      )
        .mul(eurPriceUsd)
        .div(toBN(toWei("1")))
    );
    return tvl;
  }, [kit]);

  return useAsyncState(null, call);
};
