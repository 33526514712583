import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Text } from "theme-ui";
import { PickDeposit } from "pages/DepositPage/MobileDepositPage/PickDeposit";
import { DepositIllustration } from "assets/DepositIllustration";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

interface IProps {
  onDepositClick?: () => void;
}

export const DoDeposit: React.FC<IProps> = ({ onDepositClick }) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  return (
    <>
      <Grid sx={{ gridTemplateColumns: ["auto", "1.3fr 1fr"], gridGap: 6 }}>
        <Container>
          <Text sx={{ display: "block" }} variant="title">
            {t("deposit.desktop.title")}
          </Text>
          <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
            {t("deposit.desktop.subtitle")}
          </Text>
          <PickDeposit onDepositClick={onDepositClick} />
        </Container>
        {breakpoint === Breakpoint.DESKTOP && (
          <Flex sx={{ justifyContent: "center" }}>
            <DepositIllustration />
          </Flex>
        )}
      </Grid>
    </>
  );
};
