import React from "react";
import { DoRepay } from "pages/RepayPage/DesktopRepayPage/DoRepay";
import { RepayReceipt } from "pages/RepayPage/DesktopRepayPage/RepayReceipt";

enum RepayStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

const DesktopRepayPage = () => {
  const [repayStep, setRepayStep] = React.useState(RepayStep.DO);

  switch (repayStep) {
    case RepayStep.DO:
      return (
        <DoRepay
          onRepayClick={async () => {
            setRepayStep(RepayStep.RECEIPT);
          }}
        />
      );
    case RepayStep.RECEIPT:
      return (
        <RepayReceipt
          onDoneClick={() => {
            setRepayStep(RepayStep.DO);
          }}
        />
      );
  }
};

export default DesktopRepayPage;
