import React, { useRef, useState } from "react";
import styled from "@emotion/styled/macro";
import { Check, ChevronDown } from "react-feather";
import { Box, Flex } from "@theme-ui/components";
import { CloseOnClickaway } from "./CloseOnClickaway";

export interface Option {
  label: string;
  value: string;
}

interface IProps {
  options: Array<Option>;
  onSelect: (option: Option) => void;
  value?: string;
}

const SelectWrapper = styled(Flex)`
  border-radius: 6px;
  justify-content: space-between;
  padding: 16px;
  cursor: pointer;
`;

const OptionsContainer = styled(Box)`
  box-shadow: 0 2px 16px rgba(0, 29, 202, 0.16);
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background: white;
  position: absolute;
  top: 66px;
  width: 100%;
  left: 0;
`;

const SelectedOptionCheck = styled(Check)`
  margin-right: 16px;
`;

export const StyledSelect: React.FC<IProps> = ({
  options,
  onSelect,
  value,
}) => {
  const [expand, setExpand] = useState(false);

  const select = useRef<any>(null);

  function handleSelect(option: Option) {
    onSelect(option);
  }
  return (
    <CloseOnClickaway
      onClickaway={(e) => {
        if (select?.current?.contains(e.target)) {
          return;
        }
        setExpand(false);
      }}
    >
      <SelectWrapper
        ref={select}
        onClick={() => {
          setExpand(!expand);
        }}
        sx={{
          position: "relative",
          border: expand ? "1px solid #7C71FC" : "1px solid #E0E0E0",
          backgroundColor: expand ? "purple25" : "white",
        }}
      >
        {value ? options.filter((opt) => opt?.value === value)[0]?.label : ""}
        <ChevronDown />
        <OptionsContainer hidden={!expand}>
          {options && options.length > 0
            ? options.map((option) => {
                return (
                  <Flex
                    sx={{
                      padding: "16px",
                      borderTopStyle: "solid",
                      borderTopWidth: "1px",
                      borderTopColor: "lightgray",
                      alignItems: "center",
                      fontSize: "14px",
                      lineHeight: "18px",
                      width: "100%",
                      "&:hover": {
                        color: "accent",
                      },
                      "&:nth-child(1)": {
                        borderTop: "1px solid transparent",
                      },
                    }}
                    onClick={() => handleSelect(option)}
                  >
                    {value === option.value ? (
                      <SelectedOptionCheck />
                    ) : (
                      <Box
                        sx={{
                          width: "24px",
                          height: "24px",
                          marginRight: "16px",
                        }}
                      />
                    )}
                    {option.label}
                  </Flex>
                );
              })
            : null}
        </OptionsContainer>
      </SelectWrapper>
    </CloseOnClickaway>
  );
};

StyledSelect.defaultProps = {
  options: [{ value: "", label: "..." }],
};
