import { atom } from "recoil";

export const requestAmount = atom({
  key: "REQUEST_AMOUNT",
  default: "0",
});

export const requestCurrency = atom({
  key: "REQUEST_CURRENCY",
  default: "cUSD",
});

export const requestLoading = atom({
  key: "REQUEST_LOADING",
  default: false,
});

export const requestTxHash = atom({
  key: "REQUEST_TX_HASH",
  default: "",
});

export const requestLink = atom<string | null>({
  key: "REQUEST_LINK",
  default: null,
});
