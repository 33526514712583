import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Text } from "theme-ui";
import { PickRepay } from "pages/RepayPage/MobileRepayPage/PickRepay";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { LoansIllustration } from "../../../assets/LoansIllustration";

interface IProps {
  onRepayClick?: () => void;
}

export const DoRepay: React.FC<IProps> = ({ onRepayClick }) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  return (
    <>
      <Grid sx={{ gridTemplateColumns: ["auto", "1.3fr 1fr"], gridGap: 6 }}>
        <Container>
          <Text sx={{ display: "block" }} variant="title">
            {t("repay.desktop.title")}
          </Text>
          <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
            {t("repay.desktop.subtitle")}
          </Text>
          <PickRepay onRepayClick={onRepayClick} />
        </Container>
        {breakpoint === Breakpoint.DESKTOP && (
          <Flex sx={{ variant: "flex.centered" }}>
            <LoansIllustration />
          </Flex>
        )}
      </Grid>
    </>
  );
};
