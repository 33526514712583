import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { BlockscoutTxLink } from "components/Links";
import moment from "moment";
import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { shortenAccount } from "utils/account";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import {
  withdrawAmount,
  withdrawCurrency,
  withdrawRecipient,
  withdrawTxHash,
} from "../state";

interface IProps {
  onDoneClick: () => void;
}

export const WithdrawReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const [amount] = useRecoilState(withdrawAmount);
  const [currency] = useRecoilState(withdrawCurrency);
  const [recipient] = useRecoilState(withdrawRecipient);
  const [txHash] = useRecoilState(withdrawTxHash);
  const { relayerFee } = useRelayer(amount, currency);

  const finalWithdrawAmount = Number(amount) + Number(relayerFee);

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
        Your {currency} has been sent.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <SummaryTable
        title="Transaction"
        lineItems={[
          {
            label: "Time completed",
            value: moment().format("h:mm a"),
          },
          {
            label: "Account",
            value: shortenAccount(recipient),
          },
          {
            label: "Withdraw amount",
            value: `${humanFriendlyNumber(amount)} ${currency}`,
          },
        ]}
        totalItem={{
          label: "Est. Withdrawal",
          value: `${humanFriendlyNumber(finalWithdrawAmount)} ${currency}`,
        }}
      />

      <ActionDrawer>
        <Flex sx={{ justifyContent: "space-between" }}>
          <LabelWithBalance
            label="Withdrew"
            amount={finalWithdrawAmount}
            currency={currency}
          />
          <Button
            onClick={() => {
              onDoneClick();
            }}
            variant="done"
          >
            Done
          </Button>
        </Flex>
      </ActionDrawer>
    </Container>
  );
};
