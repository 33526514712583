import React from "react";
import { Desktop } from "components/TransactionsHistory/Desktop";
// import { Mobile } from "components/TransactionsHistory/Mobile";
// import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { useTransactions } from "hooks/useTransactions";

export const TransactionsHistory: React.FC = () => {
  const txns = useTransactions();

  // if (breakpoint === Breakpoint.MOBILE) {
  //   return <Mobile txns={txns} />;
  // }

  return <Desktop txns={txns} />;
};
