import { useAvalancheTVL } from "./useAvalancheTVL";
import { useCeloTVL } from "./useCeloTVL";
import { useEthereumTVL } from "./useEthereumTVL";
import { useFantomTVL } from "./useFantomTVL";
import { usePolygonTVL } from "./usePolygonTVL";

export const useTVL = () => {
  const [celoTVL] = useCeloTVL();
  const [fantomTVL] = useFantomTVL();
  const [avalancheTVL] = useAvalancheTVL();
  const [polygonTVL] = usePolygonTVL();
  const [ethereumTVL] = useEthereumTVL();

  if (
    celoTVL === null ||
    fantomTVL === null ||
    avalancheTVL === null ||
    polygonTVL === null ||
    ethereumTVL === null
  ) {
    return null;
  }

  return celoTVL
    .add(fantomTVL)
    .add(avalancheTVL)
    .add(polygonTVL)
    .add(ethereumTVL);
};
