import { createContext, useState, useMemo, useEffect } from 'react';

export const CurrenciesContext = createContext({
    currencies: {},
    setCurrency: (currency, value) => {},
    setCurrencies: (val) => {},
    convert: (value, from, to) => 0.00,
    toUSD: (value, from) => 0.00,

});

export const CurrenciesProvider = ({pools, children}) => {
    const [currencies, setCurrencies] = useState({});

    const convert = (value, from, to = 'USD') => {
        const rate = currencies[from];
        if (rate === undefined) {
            return 0.00;
        }

        return (parseFloat(value) * rate);
    };

    useEffect(() => {
        const call = async () => {
            const result = {};
            try {
                await Promise.all(
                    pools.map(async (pool, idx) => {
                        const c = currencies[pool.symbol];
                        if (c) {
                            result[pool.symbol] = c;
                            return;
                        }

                        const response = await fetch(
                            "https://api.coinbase.com/v2/exchange-rates?currency=" +
                            pool.symbol,
                            { method: "GET" }
                        );
                        const data = await response.json();
                        result[pool.symbol] = parseFloat(data.data.rates["USD"]);
                    })
                );
                // setCurrencies(result);
            } catch (e) {
                console.log('Error fetching currency exchange rates: ', e);
            }
            setCurrencies(result);
        };
        call();
    }, [pools]); //eslint-disable-line

    const value = useMemo(
        () => {
            return {
                // setPools,
                currencies,
                setCurrency: (currency, value) => {
                    setCurrencies({ ...currencies, [currency]: value});
                    
                },
                setCurrencies,
                convert,
                toUSD: (value, from) => convert(value, from, 'USD'),
            }
        },
        [pools, currencies] //eslint-disable-line
    );

    return <CurrenciesContext.Provider value={value}>{children}</CurrenciesContext.Provider>;
};