import React from "react";
import { ConfirmBorrow } from "pages/BorrowPage/MobileBorrowPage/ConfirmBorrow";
import { BorrowReceipt } from "pages/BorrowPage/MobileBorrowPage/BorrowReceipt";
import { DoBorrow } from "../DesktopBorrowPage/DoBorrow";

enum BorrowStep {
  PICKER = "PICKER",
  CONFIRM = "CONFIRM",
  RECEIPT = "RECEIPT",
}

export const MobileBorrowPage = () => {
  const [borrowStep, setBorrowStep] = React.useState(BorrowStep.PICKER);

  switch (borrowStep) {
    case BorrowStep.PICKER:
      return (
        <DoBorrow
          onBorrowClick={() => {
            setBorrowStep(BorrowStep.RECEIPT);
          }}
        />
      );
    // This path is never displayed
    case BorrowStep.CONFIRM:
      return (
        <ConfirmBorrow
          onBackClick={() => setBorrowStep(BorrowStep.PICKER)}
          onConfirmClick={() => setBorrowStep(BorrowStep.RECEIPT)}
        />
      );
    case BorrowStep.RECEIPT:
      return (
        <BorrowReceipt
          onDoneClick={() => {
            setBorrowStep(BorrowStep.PICKER);
          }}
        />
      );
  }
};
