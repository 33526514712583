import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Text } from "theme-ui";
import { PickRequest } from "pages/RequestPage/MobileRequestPage/PickRequest";
import { DepositIllustration } from "assets/DepositIllustration";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

interface IProps {
  onRequestClick?: () => void;
}

export const DoRequest: React.FC<IProps> = ({ onRequestClick }) => {
  const { t } = useTranslation();
  const breakpoint = useBreakpoint();

  return (
    <>
      <Grid sx={{ gridTemplateColumns: ["auto", "1.3fr 1fr"], gridGap: 6 }}>
        <Container>
          <Text sx={{ display: "block" }} variant="title">
            {t("request.desktop.title")}
          </Text>
          <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
            {t("request.desktop.subtitle")}
          </Text>
          <PickRequest onRequestClick={onRequestClick} />
        </Container>
        {breakpoint === Breakpoint.DESKTOP && (
          <Flex sx={{ justifyContent: "center" }}>
            <DepositIllustration />
          </Flex>
        )}
      </Grid>
    </>
  );
};
