import React from "react";
import { Button, Container, Flex, Text } from "theme-ui";
import { BackButton } from "components/BackButton";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useRecoilState } from "recoil";
import { requestAmount, requestCurrency, requestLoading } from "../state";
import { NETWORK_COST } from "config";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { WalletGlobal } from "hooks/useWallet";

interface IProps {
  onBackClick: () => void;
  onConfirmClick: () => void;
}

export const ConfirmRequest: React.FC<IProps> = ({
  onBackClick,
  onConfirmClick,
}) => {
  const { gasCurrency } = WalletGlobal.useContainer();
  const [amount] = useRecoilState(requestAmount);
  const [currency] = useRecoilState(requestCurrency);
  const [loading] = useRecoilState(requestLoading);
  const totalCost = Number(amount) + Number(NETWORK_COST);

  let button = (
    <Button onClick={onConfirmClick} variant="primary">
      Request
    </Button>
  );

  return (
    <Container>
      <BackButton onClick={onBackClick} />
      <br />
      <Text sx={{ mb: 1 }} variant="subtitle">
        Transaction Summary
      </Text>
      <br />
      <Text sx={{ mb: 4 }} variant="regularGray">
        Please review the transaction before continuing
      </Text>
      <br />
      <SummaryTable
        title="Summary"
        lineItems={[
          {
            label: "Request Amount",
            value: `${humanFriendlyNumber(amount)} ${currency}`,
          },
          {
            label: "Est. Network Fee",
            value: `${humanFriendlyNumber(NETWORK_COST)} ${gasCurrency}`,
          },
        ]}
        totalItem={{
          label: "Est. Total",
          value:
            currency === gasCurrency
              ? `${humanFriendlyNumber(
                  Number(amount) + Number(NETWORK_COST)
                )} ${gasCurrency}`
              : `${humanFriendlyNumber(
                  Number(amount)
                )} ${currency} + ${humanFriendlyNumber(
                  NETWORK_COST
                )} ${gasCurrency}`,
        }}
      />

      <ActionDrawer>
        {loading ? (
          <Flex sx={{ justifyContent: "flex-end" }}>
            <ProofGenLoading />
          </Flex>
        ) : (
          <Flex sx={{ justifyContent: "space-between" }}>
            <LabelWithBalance
              label="Total"
              amount={totalCost.toString()}
              currency={currency}
            />
            {button}
          </Flex>
        )}
      </ActionDrawer>
    </Container>
  );
};
