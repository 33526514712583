export const LoansIllustration: React.FC = () => {
  return (
    <svg
      width="192"
      height="226"
      viewBox="0 0 192 226"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 148V220"
        stroke="#7C71FC"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6 208H138C138 201.635 135.471 195.53 130.971 191.03C126.47 186.529 120.365 184 114 184H84C84 177.635 81.4714 171.53 76.9706 167.03C72.4697 162.529 66.3652 160 60 160H6"
        fill="#ECE6FE"
      />
      <path
        d="M6 208H138C138 201.635 135.471 195.53 130.971 191.03C126.47 186.529 120.365 184 114 184H84C84 177.635 81.4714 171.53 76.9706 167.03C72.4697 162.529 66.3652 160 60 160H6"
        stroke="#7C71FC"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M54 184H84"
        stroke="#7C71FC"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M114 76.0001C114 80.7277 114.931 85.409 116.74 89.7767C118.55 94.1444 121.201 98.1131 124.544 101.456C127.887 104.799 131.856 107.451 136.223 109.26C140.591 111.069 145.272 112 150 112C154.728 112 159.409 111.069 163.777 109.26C168.144 107.451 172.113 104.799 175.456 101.456C178.799 98.1131 181.45 94.1444 183.26 89.7767C185.069 85.409 186 80.7277 186 76.0001C186 71.2725 185.069 66.5912 183.26 62.2235C181.45 57.8558 178.799 53.8872 175.456 50.5443C172.113 47.2014 168.144 44.5496 163.777 42.7405C159.409 40.9313 154.728 40.0001 150 40.0001C145.272 40.0001 140.591 40.9313 136.223 42.7405C131.856 44.5496 127.887 47.2014 124.544 50.5443C121.201 53.8872 118.55 57.8558 116.74 62.2235C114.931 66.5912 114 71.2725 114 76.0001Z"
        fill="#F5F2FF"
        stroke="#7C71FC"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M150 136C150 139.884 149.058 143.709 147.255 147.148C145.452 150.588 142.841 153.538 139.646 155.746C136.452 157.954 132.769 159.354 128.915 159.825C125.06 160.297 121.148 159.826 117.516 158.453C113.883 157.081 110.638 154.847 108.059 151.944C105.479 149.041 103.643 145.555 102.707 141.786C101.771 138.018 101.764 134.078 102.686 130.306C103.608 126.533 105.431 123.041 108 120.128"
        stroke="#7C71FC"
        stroke-width="1.75"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M16.9395 24.4412C16.1709 24.1483 16.1714 22.7961 16.9392 22.5006C20.2183 21.2386 25.6189 18.8204 28.4707 15.6815C31.1044 12.7827 33.2842 6.55648 34.4063 2.88129C34.6639 2.03768 35.8287 2.02291 36.1021 2.86028C37.3013 6.53272 39.6028 12.7771 42.2049 15.6815C44.8076 18.5864 50.4038 21.1556 53.6939 22.4938C54.444 22.7989 54.431 24.0991 53.6756 24.3872C50.3728 25.647 44.7557 28.104 42.0653 31.1068C39.4186 34.0609 37.2452 40.1765 36.1007 43.884C35.8362 44.741 34.629 44.7358 34.3698 43.8767C33.2515 40.1699 31.1195 34.0631 28.4707 31.1068C25.8152 28.1428 20.2835 25.7151 16.9395 24.4412Z"
        fill="#ECE6FE"
      />
      <path
        d="M49.0037 84.1642C48.9719 84.1509 48.8874 84.0827 48.8872 83.8793C48.887 83.6758 48.9718 83.6048 49.0052 83.5907C50.9686 82.7605 53.6412 81.4197 55.2116 79.6911C55.9712 78.8551 56.6422 77.6545 57.2068 76.4242C57.7768 75.1821 58.2627 73.8547 58.6375 72.7235C58.6512 72.6822 58.7084 72.5994 58.8655 72.5974C59.0225 72.5955 59.0797 72.6765 59.0936 72.7162C59.4904 73.8473 60.0002 75.1764 60.5861 76.4201C61.1665 77.6522 61.8455 78.8527 62.5932 79.6873C63.3412 80.5221 64.4172 81.2802 65.5214 81.9281C66.6361 82.5822 67.8273 83.1513 68.8408 83.5941C68.8763 83.6096 68.949 83.6735 68.9473 83.8491C68.9457 84.0248 68.8714 84.0889 68.8344 84.1042C67.8154 84.5258 66.6168 85.0739 65.4897 85.7191C64.3723 86.3589 63.28 87.1193 62.5082 87.9808C61.7567 88.8195 61.0917 89.9965 60.529 91.2067C59.9617 92.4269 59.4764 93.7295 59.0972 94.8555C59.0849 94.8922 59.024 94.9843 58.846 94.9836C58.6679 94.9829 58.6071 94.8901 58.5948 94.8529C58.2232 93.7273 57.7461 92.426 57.185 91.2067C56.6285 89.9975 55.9676 88.8207 55.2151 87.9808C54.4598 87.1377 53.3958 86.3928 52.302 85.7635C51.1989 85.1289 50.0208 84.5868 49.0037 84.1642Z"
        stroke="#7C71FC"
        stroke-width="1.4805"
      />
      <path
        d="M91.9783 25.5807C91.9465 25.5674 91.862 25.4992 91.8618 25.2958C91.8616 25.0923 91.9464 25.0213 91.9798 25.0072C93.9432 24.1771 96.6158 22.8362 98.1863 21.1076C98.9458 20.2716 99.6168 19.071 100.181 17.8407C100.751 16.5986 101.237 15.2712 101.612 14.14C101.626 14.0987 101.683 14.0159 101.84 14.0139C101.997 14.012 102.054 14.093 102.068 14.1327C102.465 15.2638 102.975 16.5929 103.561 17.8366C104.141 19.0687 104.82 20.2692 105.568 21.1038C106.316 21.9386 107.392 22.6967 108.496 23.3446C109.611 23.9987 110.802 24.5678 111.815 25.0107C111.851 25.0261 111.924 25.09 111.922 25.2656C111.92 25.4413 111.846 25.5054 111.809 25.5207C110.79 25.9423 109.591 26.4904 108.464 27.1357C107.347 27.7754 106.255 28.5358 105.483 29.3973C104.731 30.236 104.066 31.413 103.504 32.6232C102.936 33.8434 102.451 35.146 102.072 36.2721C102.059 36.3087 101.999 36.4008 101.821 36.4001C101.643 36.3994 101.582 36.3066 101.569 36.2694C101.198 35.1438 100.721 33.8425 100.16 32.6232C99.6031 31.414 98.9422 30.2372 98.1897 29.3973C97.4344 28.5542 96.3705 27.8093 95.2766 27.18C94.1735 26.5454 92.9954 26.0033 91.9783 25.5807Z"
        stroke="#7C71FC"
        stroke-width="1.4805"
      />
      <path
        d="M10.5011 23.7033C10.4729 23.6925 10.454 23.6758 10.4366 23.6445C10.4157 23.6071 10.3977 23.5466 10.3977 23.4719C10.3977 23.3973 10.4157 23.3362 10.437 23.298C10.4548 23.2659 10.4743 23.2487 10.5028 23.2377C12.158 22.6007 14.3568 21.6689 16.5079 20.4954C18.6487 19.3275 20.7913 17.8941 22.2972 16.2367C23.7035 14.6887 24.949 12.3061 25.972 9.90768C27.0024 7.49203 27.8377 4.9858 28.4035 3.1328C28.4136 3.09953 28.429 3.07896 28.454 3.06107C28.4842 3.03951 28.5325 3.02082 28.592 3.02007C28.6516 3.01933 28.7001 3.03681 28.7304 3.05744C28.7554 3.07449 28.771 3.0944 28.7817 3.12727C29.3859 4.97766 30.2708 7.48539 31.3365 9.90313C32.3948 12.304 33.6616 14.6867 35.0469 16.2328C36.4324 17.7793 38.5677 19.1934 40.7192 20.3748C42.8858 21.5645 45.1329 22.5521 46.7906 23.2264C46.82 23.2383 46.8378 23.2556 46.8531 23.2837C46.8717 23.3176 46.8874 23.3718 46.8867 23.4384C46.8861 23.5049 46.8693 23.559 46.8499 23.5928C46.8338 23.6209 46.8154 23.6381 46.7856 23.6494C45.1202 24.2846 42.8626 25.2248 40.6759 26.3897C38.5034 27.5469 36.3387 28.9578 34.9073 30.5555C33.4987 32.1276 32.2527 34.4915 31.226 36.875C30.1927 39.2739 29.3533 41.7605 28.7762 43.63C28.7663 43.6622 28.7511 43.6831 28.7236 43.7021C28.6906 43.7249 28.6373 43.7445 28.5718 43.7442C28.5064 43.7439 28.4532 43.7239 28.4202 43.7007C28.3927 43.6814 28.3775 43.6602 28.3678 43.6279C27.8038 41.7584 26.9812 39.2735 25.9612 36.8759C24.9476 34.4936 23.7113 32.13 22.3006 30.5555C20.8868 28.9774 18.7525 27.5836 16.6002 26.4366C14.4339 25.2821 12.1872 24.3456 10.5011 23.7033Z"
        stroke="#7C71FC"
        stroke-width="1.4805"
      />
    </svg>
  );
};
