import React from "react";
import { DoDeposit } from "pages/DepositPage/DesktopDepositPage/DoDeposit";
import { DepositReceipt } from "pages/DepositPage/DesktopDepositPage/DepositReceipt";

enum DepositStep {
  DO = "DO",
  RECEIPT = "RECEIPT",
}

const DesktopDepositPage = () => {
  const [depositStep, setDepositStep] = React.useState(DepositStep.DO);

  switch (depositStep) {
    case DepositStep.DO:
      return (
        <DoDeposit
          onDepositClick={async () => {
            setDepositStep(DepositStep.RECEIPT);
          }}
        />
      );
    case DepositStep.RECEIPT:
      return (
        <DepositReceipt
          onDoneClick={() => {
            setDepositStep(DepositStep.DO);
          }}
        />
      );
  }
};

export default DesktopDepositPage;
