import React from "react";
import MobileCompleteRequestPage from "pages/CompleteRequestPage/MobileCompleteRequestPage";
import DesktopCompleteRequestPage from "pages/CompleteRequestPage/DesktopCompleteRequestPage";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

const CompleteRequestPage: React.FC = () => {
  const breakpoint = useBreakpoint();

  if (breakpoint === Breakpoint.MOBILE) {
    return <MobileCompleteRequestPage />;
  }

  return <DesktopCompleteRequestPage />;
};

export default CompleteRequestPage;
