import { atom, useRecoilState } from "recoil";
import { InfoDrawer } from "components/InfoDrawer";
import { NetworkDetails } from "components/Network/NetworkDetails";

export const networkDrawerOpen = atom({
  key: "WALLET_DRAWER_OPEN",
  default: false,
});

export const NetworkDrawer: React.FC = () => {
  const [isOpen, setIsOpen] = useRecoilState(networkDrawerOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <InfoDrawer>
      <NetworkDetails onClose={() => setIsOpen(false)} />
    </InfoDrawer>
  );
};
