import React from "react";
import { Container, Grid, Text } from "theme-ui";
import { Box } from "@theme-ui/components";
import CreditSummary from "./CreditSummary";
import SingleCreditSummary from "./SingleCreditSumarry";
import { AssetsWithSummary } from "../../hooks/useAssets";
import { TimedSpinner } from "../../components/Loaders/TimedSpinner";

const LoansSummary: React.FC<{
  assetsWithSummary: AssetsWithSummary;
}> = ({ assetsWithSummary }) => {
  function getSingleCreditSummaries() {
    if (assetsWithSummary && assetsWithSummary.assets.length > 0) {
      return assetsWithSummary.assets.map((asset: any) => (
        <SingleCreditSummary asset={asset} />
      ));
    } else {
      console.log("Loading");
      return <TimedSpinner estimatedTimeInSeconds={5} />;
    }
  }

  return (
    <Box>
      <Text sx={{ display: "block" }} variant="title" mb={2}>
        Credit summary
      </Text>
      <Grid
        sx={{ gridTemplateColumns: ["1fr", "1fr", "1fr 3fr"], gridGap: 20 }}
      >
        <Container>
          {assetsWithSummary && (
            <CreditSummary summary={assetsWithSummary.summary} />
          )}
        </Container>
        <Container>{getSingleCreditSummaries()}</Container>
      </Grid>
    </Box>
  );
};

export default LoansSummary;
