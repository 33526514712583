import React from "react";
import { Card, Flex, Grid, Text } from "@theme-ui/components";
import styled from "@emotion/styled";

const Outline = styled(Card)({
  padding: "26px 24px",
  border: "1px solid #ECE6FE",
  boxSizing: "border-box",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
});

const Header = styled.div({
  fontWeight: 400,
  fontSize: [14],
  lineHeight: "20px",
  color: "#757575",
});

const Row = styled(Grid)({
  borderBottom: "1px solid #ECE6FE",
  margin: "0 -24px",
  padding: "22px 24px",
  ":first-child": {
    marginTop: "-26px",
  },
  ":last-child": {
    borderBottom: "none",
  },
});

export const Mobile: React.FC<{
  assets?: any;
}> = ({ assets }) => {
  return (
    <Outline>
      <Row columns={["1fr"]}>
        <Header>Portfolio</Header>
      </Row>
      {assets &&
        assets.map((a: any) => (
          <Row columns={["1fr 2fr 2fr"]} gap={[0]}>
            {a.icon}
            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {a.amount_formatted}
              </Text>
              <Text
                sx={{
                  fontWeight: [400],
                  fontSize: [14],
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                ${a.amount_usd}
              </Text>
            </Flex>
          </Row>
        ))}
    </Outline>
  );
};
