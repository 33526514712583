import { Box, Spinner } from "@theme-ui/components";
import { useAvaxAaveApr } from "hooks/apr/useAvaxAaveApr";
import { useEthAaveApr } from "hooks/apr/useEthAaveApr";
import { useGeistApr } from "hooks/apr/useGeistApr";
import { useMaticAaveApr } from "hooks/apr/useMaticAaveApr";
import { useMoolaApr } from "hooks/apr/useMoolaApr";

interface IProps {
  symbol: string;
}

export const APRLabel: React.FC<IProps> = ({ symbol }) => {
  const [moolaApr] = useMoolaApr(symbol);
  const [geistApr] = useGeistApr(symbol);
  const [maticAaveApr] = useMaticAaveApr(symbol);
  const [avaxAaveApr] = useAvaxAaveApr(symbol);
  const [ethAaveApr] = useEthAaveApr(symbol);
  if (
    moolaApr != null ||
    geistApr != null ||
    maticAaveApr != null ||
    avaxAaveApr != null ||
    ethAaveApr != null
  ) {
    return (
      <Box
        sx={{
          padding: 1,
          borderRadius: "12px",
          color: "#00A571",
        }}
      >
        {moolaApr?.toString() ||
          geistApr?.toString() ||
          maticAaveApr?.toString() ||
          avaxAaveApr?.toString() ||
          ethAaveApr?.toString()}
        %
      </Box>
    );
  }
  return <Spinner />;
};
