import React from "react";
import { ConfirmRepay } from "pages/RepayPage/MobileRepayPage/ConfirmRepay";
import { RepayReceipt } from "pages/RepayPage/MobileRepayPage/RepayReceipt";
import { DoRepay } from "../DesktopRepayPage/DoRepay";

enum RepayStep {
  PICKER = "PICKER",
  CONFIRM = "CONFIRM",
  RECEIPT = "RECEIPT",
}

// pass props and State interface to Component class
const MobileRepayPage = () => {
  const [repayStep, setRepayStep] = React.useState(RepayStep.PICKER);

  switch (repayStep) {
    case RepayStep.PICKER:
      return (
        <DoRepay
          onRepayClick={() => {
            setRepayStep(RepayStep.RECEIPT);
          }}
        />
      );
    // This path is never displayed
    case RepayStep.CONFIRM:
      return (
        <ConfirmRepay
          onBackClick={() => setRepayStep(RepayStep.PICKER)}
          onConfirmClick={() => {
            setRepayStep(RepayStep.RECEIPT);
          }}
        />
      );
    case RepayStep.RECEIPT:
      return (
        <RepayReceipt
          onDoneClick={() => {
            setRepayStep(RepayStep.PICKER);
          }}
        />
      );
  }
};

export default MobileRepayPage;
