import React from "react";
import { PoofKitGlobal } from "hooks/usePoofKit";
import { toBN, toWei } from "web3-utils";
import { useRecoilState } from "recoil";
import {
  requestAmount,
  requestCurrency,
  requestLoading,
} from "pages/RequestPage/state";
import { PoofAccountGlobal } from "hooks/poofAccount";
import qs from "query-string";
import { Page } from "state/global";
import { useWeb3 } from "hooks/useWeb3";

export type RequestParams = {
  data: string;
  poolAddress: string;
  amount: string;
  currency: string;
};

export function useRequest() {
  const [amount] = useRecoilState(requestAmount);
  const [currency] = useRecoilState(requestCurrency);
  const [, setLoading] = useRecoilState(requestLoading);
  const { poofKit, progress } = PoofKitGlobal.useContainer();
  const { poofAccount, privateKey } = PoofAccountGlobal.useContainer();
  const { network } = useWeb3();

  const request = React.useCallback(async () => {
    if (progress < 1) {
      alert("Poof kit still loading");
      return;
    }
    if (!poofAccount || !privateKey) {
      alert("No poof account or private key");
      return;
    }
    setLoading(true);
    const poolMatch = await poofKit.poolMatch(currency);
    if (poolMatch) {
      try {
        const res = await poofKit.deposit(
          privateKey,
          currency,
          toBN(toWei(amount)),
          toBN(0)
        );
        const queryString = qs.stringify({
          data: res.encodeABI(),
          poolAddress: poolMatch.poolAddress,
          amount: toWei(amount),
          currency,
          chainId: network.chainId,
        });
        return `/#/${Page.COMPLETE_REQUEST}?${queryString}`;
      } catch (e) {
        throw e;
      } finally {
        setLoading(false);
      }
    }
  }, [
    amount,
    currency,
    poofAccount,
    poofKit,
    progress,
    privateKey,
    setLoading,
    network.chainId,
  ]);

  return request;
}
