import React from "react";
import { Desktop } from "components/HomeButton/Desktop";
// import { Mobile } from "components/HomeButton/Mobile";
// import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";

export const HomeButton: React.FC<{
  to: string;
  label: string;
  description?: string;
  icon?: any; //TODO: get proper type
}> = ({ to, label, description, icon }) => {
  // const breakpoint = useBreakpoint();

  // if (breakpoint === Breakpoint.MOBILE) {
  //   return <Mobile to={to} label={label} description={description} />;
  // }

  return (
    <Desktop to={to} label={label} description={description} icon={icon} />
  );
};
