import React from "react";
import { Button, Text, Spinner, Grid } from "@theme-ui/components";
import { Box, Flex, Input, Link } from "theme-ui";
import { ActionDrawer } from "components/ActionDrawer";
import { LabelWithBalance } from "components/LabelWithBalance";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { deployments } from "@poofcash/poof-v2-kit";
import { useRecoilState } from "recoil";
import { repayAmount, repayCurrency, RepayStage, repayStage } from "../state";
import { useRepay } from "hooks/actions/useRepay";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useTokenBalance } from "hooks/useTokenBalance";
import { fromWei, toBN } from "web3-utils";
import { useHiddenBalances } from "hooks/useHiddenBalances";
import { ProofGenLoading } from "components/Loaders/ProofGenLoading";
import { WalletGlobal } from "hooks/useWallet";
import { StyledSelect } from "../../../components/StyledSelect";
import { useRequestParams } from "../../../hooks/useRequestParams";

interface IProps {
  onRepayClick?: () => void;
}

// pass props and State interface to Component class
export const PickRepay: React.FC<IProps> = ({ onRepayClick }) => {
  const { network, address, connect } = WalletGlobal.useContainer();
  const [connected, setConnected] = React.useState(false);
  const [amount, setAmount] = useRecoilState(repayAmount);
  const [currency, setCurrency] = useRecoilState(repayCurrency);
  React.useEffect(() => {
    setCurrency(
      deployments[network.chainId].find((d) => d.pSymbol === "pUSD")?.pSymbol ||
        deployments[network.chainId][0].pSymbol
    );
  }, [network.chainId, setCurrency]);
  const [stage, setStage] = useRecoilState(repayStage);
  const [balance] = useTokenBalance(currency, address);
  const [hiddenBalances] = useHiddenBalances(currency);

  const maxRepay = Math.min(
    Number(fromWei(hiddenBalances?.[0]?.debt.toString() ?? toBN(0))),
    Number(fromWei(balance?.pBalance ?? toBN(0)))
  ).toString();

  const breakpoint = useBreakpoint();

  const repay = useRepay();

  let button = (
    <Button
      onClick={() =>
        connect()
          .then(() => setConnected(true))
          .catch((e) => {
            console.error(e);
            alert(e.message);
          })
      }
    >
      Connect Wallet
    </Button>
  );
  if (connected) {
    button = (
      <Button
        onClick={async () => {
          try {
            await repay();
            onRepayClick && onRepayClick();
          } catch (e: any) {
            console.error(e);
            alert(e.message);
          } finally {
            setStage(RepayStage.START);
          }
        }}
        disabled={
          isNaN(Number(amount)) ||
          Number(amount) === 0 ||
          Number(amount) > Number(maxRepay)
        }
      >
        Repay
      </Button>
    );
  }

  const currencyOptions = deployments[network.chainId].map((entry, idx) => ({
    value: entry.pSymbol,
    label: entry.pSymbol,
  }));

  const params = useRequestParams();
  if (params.currency) {
    const currencyFromUrl = params.currency;
    console.log(deployments[network.chainId]);
    const foundCurrency = deployments[network.chainId].find(
      (currency) => currency.pSymbol === currencyFromUrl
    );
    if (foundCurrency) {
      setCurrency(foundCurrency.pSymbol);
    }
  }

  return (
    <>
      <Box mb={3} mt={3}>
        <Grid columns={"1fr 2fr"} gap={20}>
          <Flex sx={{ flexDirection: "column" }} mb={1}>
            <Text variant="form">Currency</Text>
            <StyledSelect
              value={currency}
              options={currencyOptions}
              onSelect={(e) => setCurrency(e.value)}
            />
          </Flex>
          <Box mb={4}>
            <Flex sx={{ width: "100%", justifyContent: "space-between" }}>
              <Text variant="form">Amount</Text>
              {connected && (
                <Text sx={{ whiteSpace: "nowrap" }} variant="form">
                  <Link
                    sx={{ maxWidth: "100%" }}
                    onClick={() => {
                      if (balance) {
                        setAmount(maxRepay);
                      }
                    }}
                  >
                    max: {maxRepay} {currency}
                  </Link>
                </Text>
              )}
            </Flex>
            <Input
              sx={{ width: "100%" }}
              placeholder="Enter an amount to repay"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </Box>
        </Grid>
      </Box>

      {breakpoint === Breakpoint.DESKTOP && (
        <Box mt={4}>
          {stage === RepayStage.TXN_SENDING && (
            <Flex sx={{ alignItems: "center" }}>
              <Spinner mr={2} />
              <Text>Sending transaction through the network...</Text>
            </Flex>
          )}
          {stage === RepayStage.PROVING && <ProofGenLoading />}
          {stage === RepayStage.SIGNING && (
            <Text>To continue, sign the transaction in your wallet.</Text>
          )}
          {stage === RepayStage.START && button}
        </Box>
      )}

      {breakpoint === Breakpoint.MOBILE && (
        <>
          <Box mt={7}>
            <SummaryTable
              lineItems={[
                {
                  label: "Withdrawal Amount",
                  value: `${humanFriendlyNumber(amount)} ${currency}`,
                },
              ]}
              totalItem={{
                label: "Total",
                value: `${humanFriendlyNumber(amount)} ${currency}`,
              }}
            />
          </Box>
          <ActionDrawer>
            <Flex
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <LabelWithBalance
                label="Total"
                amount={amount}
                currency={currency}
              />
              {stage === RepayStage.TXN_SENDING && (
                <Flex sx={{ alignItems: "center" }}>
                  <Spinner mr={2} />
                  <Text>Sending transaction through the network...</Text>
                </Flex>
              )}
              {stage === RepayStage.PROVING && <ProofGenLoading />}
              {stage === RepayStage.SIGNING && (
                <Text>To continue, sign the transaction in your wallet.</Text>
              )}
              {stage === RepayStage.START && button}
            </Flex>
          </ActionDrawer>
        </>
      )}
    </>
  );
};
