import Web3 from "web3";
import React from "react";
import { useAsyncState } from "hooks/useAsyncState";
import WrappedAbi from "abis/WrappedMToken.json";
import AggregatorAbi from "abis/Aggregator.json";
import { AbiItem, toBN } from "web3-utils";
import { Aggregator } from "generated/Aggregator";

export const useEthereumTVL = () => {
  const web3 = React.useMemo(
    () => new Web3(process.env.REACT_APP_ETHEREUM_RPC || ""),
    []
  );

  const call = React.useCallback(async () => {
    let tvl = toBN(0);

    const waWETH = new web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0x7580345EBC7DEFD34fC886CbD5Ffb1aDEbf2f6D6"
    );
    const ethBalance = toBN(
      await waWETH.methods
        .underlyingBalanceOf("0xd3020655F6431C9aF80fdAab66Da8Ac86abE365E")
        .call()
    );
    const ethUsdOracle = (new web3.eth.Contract(
      AggregatorAbi as AbiItem[],
      "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419"
    ) as unknown) as Aggregator;
    const ethPriceUsd = toBN(
      (await ethUsdOracle.methods.latestRoundData().call()).answer
    );
    tvl = tvl.add(ethBalance.mul(ethPriceUsd).div(toBN(1e8)));

    return tvl;
  }, [web3.eth]);

  return useAsyncState(null, call);
};
