import { PoofAccountGlobal } from "hooks/poofAccount";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { Page } from "state/global";
import { PoofKitLoading } from "./Loaders/PoofKitLoading";

export const PrivateRouteWithDep: React.FC<RouteProps> = ({
  children,
  ...rest
}) => {
  const { poofAccount, privateKey } = PoofAccountGlobal.useContainer();
  return (
    <>
      <Route
        {...rest}
        render={({ location }) => {
          if (!poofAccount || !privateKey) {
            return (
              <Redirect
                to={{
                  pathname: `/${Page.SETUP}`,
                  state: { from: location },
                }}
              />
            );
          }
          return children;
        }}
      />
      <PoofKitLoading />
    </>
  );
};
