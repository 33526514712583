import React, { useState } from "react";
import { Container, Grid, Text, Button } from "theme-ui";
import { SummaryTable } from "components/SummaryTable";
import { humanFriendlyNumber } from "utils/number";
import { useRelayer } from "hooks/useRelayer";
import { useRecoilState } from "recoil";
import { withdrawAmount, withdrawCurrency } from "../state";
import { useTranslation } from "react-i18next";
import { PickWithdraw } from "../MobileWithdrawPage/PickWithdraw";
import { GrayBox } from "components/GrayBox";
import { Breakpoint, useBreakpoint } from "hooks/useBreakpoint";
import { Cog } from "assets/icons/index";
import { DepositIllustration } from "assets/DepositIllustration";

interface IProps {
  onWithdrawClick: () => void;
}

export const DoWithdraw: React.FC<IProps> = ({ onWithdrawClick }) => {
  const [settingsOpened, setSettingsOpened] = useState(false);
  const { t } = useTranslation();
  const [amount] = useRecoilState(withdrawAmount);
  const [currency] = useRecoilState(withdrawCurrency);
  const { relayer, relayerFee } = useRelayer(amount, currency);
  const breakpoint = useBreakpoint();

  const finalWithdrawAmount = Number(amount) + Number(relayerFee);

  let boxContent = <DepositIllustration />;

  if (Number(amount) > 0) {
    boxContent = (
      <Container>
        <GrayBox>
          <SummaryTable
            lineItems={[
              {
                label: "Withdrawal Amount",
                value: `${humanFriendlyNumber(amount)} ${currency}`,
              },
              {
                label: `Relayer Fee (${relayer?.relayerFee}%) + Gas`,
                value: `${relayerFee ? humanFriendlyNumber(relayerFee) : "-"
                  } ${currency}`,
              },
            ]}
            totalItem={{
              label: "Total",
              value: `${humanFriendlyNumber(finalWithdrawAmount)} ${currency}`,
            }}
          />
        </GrayBox>
      </Container>
    );
  }

  return (
    <Grid sx={{ gridTemplateColumns: ["auto", "1.3fr 1fr"], gridGap: 6 }}>
      <Container>
        <Text
          sx={{
            display: "flex",
            alignContent: "center",
            justifyContent: "flex-start",
          }}
          variant="title"
        >
          {t("withdraw.desktop.title")}{" "}
          <Button
            variant="ghost"
            onClick={() => setSettingsOpened(true)}
            sx={{ color: "#757575" }}
          >
            <Cog size={24} />
          </Button>
        </Text>
        <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
          {t("withdraw.desktop.subtitle")}
        </Text>
        <PickWithdraw
          onWithdrawClick={onWithdrawClick}
          settingsOpened={settingsOpened}
          setSettingsOpened={setSettingsOpened}
        />
      </Container>
      {breakpoint === Breakpoint.DESKTOP && boxContent}
    </Grid>
  );
};
