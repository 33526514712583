import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Flex, Grid, Text } from "theme-ui";
import { PickCompleteRequest } from "pages/CompleteRequestPage/MobileCompleteRequestPage/PickCompleteRequest";
import { DepositIllustration } from "assets/DepositIllustration";

interface IProps {
  onCompleteRequestClick?: () => void;
}

export const DoCompleteRequest: React.FC<IProps> = ({
  onCompleteRequestClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Grid sx={{ gridTemplateColumns: "1.3fr 1fr", gridGap: 6 }}>
        <Container>
          <Text sx={{ display: "block" }} variant="title">
            {t("completeRequest.desktop.title")}
          </Text>
          <Text sx={{ display: "block", mb: 4 }} variant="regularGray">
            {t("completeRequest.desktop.subtitle")}
          </Text>
          <PickCompleteRequest
            onCompleteRequestClick={onCompleteRequestClick}
          />
        </Container>
        <Flex sx={{ justifyContent: "center" }}>
          <DepositIllustration />
        </Flex>
      </Grid>
    </>
  );
};
