import { useCallback } from "react";
import { useAsyncState } from "hooks/useAsyncState";
import { enforceHex } from "utils/account";
import { toBN } from "web3-utils";
import { PoofKit } from "@poofcash/poof-v2-kit";
import { useWeb3 } from "./useWeb3";

const initial = {
  allowance: toBN(0),
  ownerBalance: toBN(0),
  pBalance: toBN(0),
};

export function useTokenBalance(
  currency?: string | null,
  owner?: string | null
) {
  const { web3 } = useWeb3();

  const balanceCall = useCallback(async () => {
    if (!owner || !currency) {
      return initial;
    }
    const poofKit = new PoofKit(web3 as any);
    const ownerFmt = enforceHex(owner);
    const allowance = toBN(await poofKit.allowance(currency, ownerFmt));
    const ownerBalance = toBN(await poofKit.balance(currency, ownerFmt));
    const pBalance = toBN(await poofKit.pBalance(currency, ownerFmt));

    return { allowance, ownerBalance, pBalance };
  }, [owner, currency, web3]);

  return useAsyncState(initial, balanceCall);
}
