import React from "react";
import { PickCompleteRequest } from "pages/CompleteRequestPage/MobileCompleteRequestPage/PickCompleteRequest";
import { ConfirmCompleteRequest } from "pages/CompleteRequestPage/MobileCompleteRequestPage/ConfirmCompleteRequest";
import { CompleteRequestReceipt } from "pages/CompleteRequestPage/MobileCompleteRequestPage/CompleteRequestReceipt";

enum CompleteRequestStep {
  PICKER = "PICKER",
  CONFIRM = "CONFIRM",
  RECEIPT = "RECEIPT",
}

// pass props and State interface to Component class
const MobileCompleteRequestPage = () => {
  const [completeRequestStep, setCompleteRequestStep] = React.useState(
    CompleteRequestStep.PICKER
  );

  switch (completeRequestStep) {
    case CompleteRequestStep.PICKER:
      return (
        <PickCompleteRequest
          onCompleteRequestClick={() => {
            setCompleteRequestStep(CompleteRequestStep.RECEIPT);
          }}
        />
      );
    // This path is never displayed
    case CompleteRequestStep.CONFIRM:
      return (
        <ConfirmCompleteRequest
          onBackClick={() => setCompleteRequestStep(CompleteRequestStep.PICKER)}
          onConfirmClick={() => {
            setCompleteRequestStep(CompleteRequestStep.RECEIPT);
          }}
        />
      );
    case CompleteRequestStep.RECEIPT:
      return (
        <CompleteRequestReceipt
          onDoneClick={() => {
            setCompleteRequestStep(CompleteRequestStep.PICKER);
          }}
        />
      );
  }
};

export default MobileCompleteRequestPage;
