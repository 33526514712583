import React from "react";
import { Button, Container, Text } from "theme-ui";
import moment from "moment";
import { BlockscoutTxLink } from "components/Links";
import { SummaryTable } from "components/SummaryTable";
import { GrayBox } from "components/GrayBox";
import { humanFriendlyNumber } from "utils/number";
import { useRecoilState } from "recoil";
import { repayAmount, repayCurrency, repayTxHash } from "../state";
import { shortenAccount } from "utils/account";
import { PoofAccountGlobal } from "hooks/poofAccount";
import { NETWORK_COST } from "config";
import { WalletGlobal } from "hooks/useWallet";

interface IProps {
  onDoneClick: () => void;
}

export const RepayReceipt: React.FC<IProps> = ({ onDoneClick }) => {
  const { gasCurrency } = WalletGlobal.useContainer();
  const [amount] = useRecoilState(repayAmount);
  const [currency] = useRecoilState(repayCurrency);
  const [txHash] = useRecoilState(repayTxHash);
  const { address } = PoofAccountGlobal.useContainer();

  return (
    <Container>
      <Text sx={{ display: "block", mb: 1 }} variant="subtitle">
        Alakazam!
      </Text>
      <Text sx={{ display: "block", mb: 4 }} variant="regular">
        You have completed repayment.{" "}
        <BlockscoutTxLink tx={txHash}>View transaction</BlockscoutTxLink>.
      </Text>

      <GrayBox>
        <Text sx={{ mb: 3 }} variant="subtitle">
          Receipt
        </Text>
        <br />
        <SummaryTable
          title="Transaction"
          lineItems={[
            {
              label: "Time completed",
              value: moment().format("h:mm a"),
            },
            {
              label: "Account",
              value: shortenAccount(address),
            },
            {
              label: "Est. total amount",
              value:
                currency === gasCurrency
                  ? `${humanFriendlyNumber(
                      Number(amount) + Number(NETWORK_COST)
                    )} ${gasCurrency}`
                  : `${humanFriendlyNumber(
                      amount
                    )} ${currency} + ${humanFriendlyNumber(
                      NETWORK_COST
                    )} ${gasCurrency}`,
            },
          ]}
          totalItem={{
            label: "Repayed",
            value: `${humanFriendlyNumber(amount)} ${currency}`,
          }}
        />
      </GrayBox>

      <Button sx={{ mt: 2, px: 6 }} variant="done" onClick={onDoneClick}>
        Done
      </Button>
    </Container>
  );
};
