import React, { useState, useEffect } from "react";
import { Flex, Button } from "theme-ui";
import WithdrawPage from "pages/WithdrawPage";
import RequestPage from "pages/RequestPage";
import { Link, useLocation } from "react-router-dom";
import { Page } from "state/global";
import styled from "@emotion/styled/macro";
import { Box } from "@theme-ui/components";

//TODO[JM]: export Switch buttons to seperate file
export const SwitchButtons = styled(Box)<{ active: string }>(({ active }) => ({
  // background: '#7C71FC',

  borderRadius: "60px",
  border: "1px solid #E0E0E0",
  boxSizing: "border-box",
  // height: '40px',
  "& a": {
    lineHeight: "20px",
  },
  "& a button": {
    lineHeight: "20px",
    borderRadius: "60px",
    color: "#7C71FC",
    background: "#fff",
    height: "40px",
    padding: "9px 25px",
    "&.active": {
      color: "#fff",
      background: "#7C71FC",
    },
  },
}));

const SendAndRequestPage: React.FC = () => {
  const [current, setCurrent] = useState(Page.SEND);
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes(Page.SEND)) {
      setCurrent(Page.SEND);
    }
    if (location.pathname.includes(Page.REQUEST)) {
      setCurrent(Page.REQUEST);
    }
  }, [location]);

  return (
    <>
      <Flex sx={{ justifyContent: "center" }}>
        <SwitchButtons active={current}>
          <Link to={Page.SEND}>
            <Button
              className={current === Page.SEND ? "active" : ""}
              sx={{ display: "inline-block" }}
            >
              Send
            </Button>
          </Link>
          <Link to={Page.REQUEST}>
            <Button
              className={current === Page.REQUEST ? "active" : ""}
              sx={{ display: "inline-block" }}
            >
              Request
            </Button>
          </Link>
        </SwitchButtons>
      </Flex>
      {current === Page.SEND && <WithdrawPage />}
      {current === Page.REQUEST && <RequestPage />}
    </>
  );
};

export default SendAndRequestPage;
