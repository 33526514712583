import { Card, Flex, Text } from "theme-ui";
import { Planet } from "phosphor-react";
import { useRecoilState } from "recoil";
import { networkDrawerOpen } from "components/Network/NetworkDrawer";
import { poofAccountDrawerOpen } from "components/PoofAccount/PoofAccountDrawer";
import { useWeb3 } from "hooks/useWeb3";

export const NetworkCard: React.FC = () => {
  const { network } = useWeb3();
  const [, setAccountDrawerIsOpen] = useRecoilState(poofAccountDrawerOpen);
  const [networkDrawerIsOpen, setNetworkDrawerIsOpen] = useRecoilState(
    networkDrawerOpen
  );

  return (
    <Card
      variant="warning"
      onClick={() => {
        if (!networkDrawerIsOpen) {
          setAccountDrawerIsOpen(false);
        }
        setNetworkDrawerIsOpen(!networkDrawerIsOpen);
      }}
    >
      <Flex sx={{ alignItems: "center" }}>
        <Planet size={32} />
        <Text variant="primary" ml={2} mt={1}>
          {network.name}
        </Text>
      </Flex>
    </Card>
  );
};
