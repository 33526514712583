import Web3 from "web3";
import React from "react";
import { useAsyncState } from "hooks/useAsyncState";
import WrappedAbi from "abis/WrappedMToken.json";
import AggregatorAbi from "abis/Aggregator.json";
import { AbiItem, toBN } from "web3-utils";
import { Aggregator } from "generated/Aggregator";

export const usePolygonTVL = () => {
  const web3 = React.useMemo(
    () => new Web3("https://matic-mainnet-full-rpc.bwarelabs.com"),
    []
  );

  const call = React.useCallback(async () => {
    let tvl = toBN(0);

    const wamMATIC = new web3.eth.Contract(
      WrappedAbi as AbiItem[],
      "0xbf03e0f7D8dFB17e4680C4D4748A614968aD5495"
    );
    const maticBalance = toBN(
      await wamMATIC.methods
        .underlyingBalanceOf("0xEfc83b8EfCc03cC2ECc28C542A7bf4D9e4Ce9a6E")
        .call()
    );
    const maticUsdOracle = (new web3.eth.Contract(
      AggregatorAbi as AbiItem[],
      "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0"
    ) as unknown) as Aggregator;
    const maticPriceUsd = toBN(
      (await maticUsdOracle.methods.latestRoundData().call()).answer
    );
    tvl = tvl.add(maticBalance.mul(maticPriceUsd).div(toBN(1e8)));

    return tvl;
  }, [web3.eth]);

  return useAsyncState(null, call);
};
