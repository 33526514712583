import React from "react";
import { Card, Flex, Grid, Text, Button } from "@theme-ui/components";
import styled from "@emotion/styled";
import {
  Deposit as DepositIcon,
  Withdrawal as WithdrawalIcon,
} from "assets/icons";
import { Link } from "react-router-dom";
import { Page } from "state/global";
import { APRLabel } from "components/APRLabel";

const Outline = styled(Card)({
  padding: "26px 24px",
  border: "1px solid #ECE6FE",
  boxSizing: "border-box",
  borderRadius: "16px",
  backgroundColor: "#ffffff",
});

const Header = styled.div({
  fontWeight: 400,
  fontSize: [14],
  lineHeight: "20px",
  color: "#757575",
});

const Row = styled(Grid)({
  borderBottom: "1px solid #ECE6FE",
  margin: "0 -24px",
  padding: "22px 24px",
  ":first-child": {
    marginTop: "-26px",
  },
  ":last-child": {
    borderBottom: "none",
  },
});

const NoStyleLink = styled(Link)({
  textDecoration: "none",
  color: "var(--theme-ui-colors-primaryButtonColor)",
});

export const Desktop: React.FC<{
  assets?: any;
}> = ({ assets }) => {
  return (
    <Outline>
      <Row columns={["1fr 1fr 2fr 2fr 1fr 2fr"]}>
        <Header>&nbsp;</Header>
        <Header>Asset</Header>
        <Header>Balance</Header>
        <Header>Credit available</Header>
        <Header>APR</Header>
        <Header>&nbsp;</Header>
      </Row>
      {assets &&
        assets.map((a: any) => (
          <Row columns={["1fr 1fr 2fr 2fr 1fr 2fr"]}>
            {a.icon}
            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [700],

                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {a.symbol}
              </Text>
              <Text
                sx={{
                  fontWeight: [400],
                  fontSize: [14],
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                {a.asset_pcnt}%
              </Text>
            </Flex>

            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {a.amount_formatted}
              </Text>
              <Text
                sx={{
                  fontWeight: [400],
                  fontSize: [14],
                  lineHeight: "18px",
                  color: "#757575",
                }}
              >
                ${a.amount_usd}
              </Text>
            </Flex>

            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                {a.credit_available}
              </Text>
            </Flex>

            <Flex
              sx={{
                alignItems: "flex-start",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Text
                sx={{
                  color: "#292B30",
                  fontWeight: [400],
                  fontSize: [16],
                  lineHeight: "20px",
                }}
              >
                <APRLabel {...a} />
              </Text>
            </Flex>

            <Flex sx={{ justifyContent: "flex-end" }}>
              <NoStyleLink to={Page.DEPOSIT}>
                <Button
                  variant="primary"
                  sx={{ width: [36], padding: "4px 0", mr: 2 }}
                >
                  <DepositIcon size={28} />
                </Button>
              </NoStyleLink>
              <NoStyleLink to={Page.REQUEST}>
                <Button
                  variant="primary"
                  sx={{ width: [36], padding: "4px 0" }}
                >
                  <WithdrawalIcon size={28} />
                </Button>
              </NoStyleLink>
            </Flex>
          </Row>
        ))}
    </Outline>
  );
};
