import React from "react";
import { Box, Button, Container, Flex, Link } from "theme-ui";
import { Page } from "state/global";
import { useHistory, useLocation } from "react-router-dom";
import { UserCircle } from "phosphor-react";
import { NetworkCard } from "components/Network/NetworkCard";
import { useRecoilState } from "recoil";
import { poofAccountDrawerOpen } from "components/PoofAccount/PoofAccountDrawer";
import { networkDrawerOpen } from "components/Network/NetworkDrawer";

const HeaderButton: React.FC<{ page: Page }> = ({ page, children }) => {
  const location = useLocation();
  const history = useHistory();
  return (
    <Button
      variant={
        location.pathname.includes(page) ? "switcherSelected" : "switcher"
      }
      onClick={() => history.push(page)}
    >
      {children}
    </Button>
  );
};

export const MobileHeader: React.FC = () => {
  const [accountDrawerIsOpen, setAccountDrawerIsOpen] = useRecoilState(
    poofAccountDrawerOpen
  );
  const [, setNetworkDrawerIsOpen] = useRecoilState(networkDrawerOpen);
  return (
    <Container sx={{ pt: 4, px: 3, width: "auto" }}>
      <Flex
        sx={{
          mb: 2,
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box
          color="primaryText"
          onClick={() => {
            if (!accountDrawerIsOpen) {
              setNetworkDrawerIsOpen(false);
            }
            setAccountDrawerIsOpen(!accountDrawerIsOpen);
          }}
        >
          <UserCircle size={36} />
        </Box>
        <NetworkCard />
      </Flex>
      <Container sx={{ overflow: "scroll" }} mt={3}>
        <Flex sx={{ width: "fit-content", borderBottom: "1px solid #E0E0E0" }}>
          <HeaderButton page={Page.HOME}>Home</HeaderButton>
          <Link
            href="https://docs.poof.cash"
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none" }}
          >
            <Button variant="switcher">Docs</Button>
          </Link>
        </Flex>
      </Container>
    </Container>
  );
};
